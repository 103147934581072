import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFields from './elementsFields';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainer/CreateUpdateContainer';

const UpdateElements = ({ purpose, element }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const model = pathnameSegments[2];
  const [resource, setResource] = useState(null);
  const [autoTR, setAutoTR] = useState(null);
  const { fields, isFieldsLoading } = useFields(t, element, autoTR);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        serviceYear: data.serviceYear && moment(data.serviceYear)
      })
    }
  };

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${model}/${id}`
      });
      setResource(data);
    } catch (e) {
      message(e);
    }
  };

  const getAutoTR = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/autotransformer?demirame=${
          model === 'depart' ? resource.demiRame : resource._id
        }`
      });
      setAutoTR(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getResource();
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      if (resource?.demiRame || (resource && model === 'demirame'))
        await getAutoTR();
    })();
  }, [resource]);

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl={element}
      resource={element}
      config={config}
    />
  );
};

UpdateElements.propTypes = {
  purpose: PropTypes.string.isRequired,
  element: PropTypes.string.isRequired
};

export default UpdateElements;
