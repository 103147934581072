import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SourcePostContextProvider } from '../sourcePost/SourcePostContext';
import ListHistory from './ListHistory';

export const HistoryRouter = () => (
  <SourcePostContextProvider>
    <Routes>
      <Route index element={<ListHistory />} />
    </Routes>
  </SourcePostContextProvider>
);
