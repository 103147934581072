import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Switch } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar';
import DisplaySchema from './DisplaySchema';
import { useSchemaContext } from './SchemaContext';
import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from '../utils/ErrorMessage';
import TransformersSchema from './TransformersSchema';
import LinkOutModal from './LinkOutModal';

const Schema = ({ id, origin }) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const {
    getElementData,
    refYear,
    selectedYear,
    showCreateSchema,
    setShowCreateSchema,
    checkIfDemiRameIsAloneForLinkOut,
    isSchemaLoading,
    setIsSchemaLoading,
    setOrigin,
    schema,
    getATforDR,
    setSwitchAlreadyUsed
  } = useSchemaContext();
  const { message } = useErrorMessage();
  const { mode } = useSchemaContext();
  const [results, setResults] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [AutoTRArray, setAutoTRArray] = useState([]);

  useEffect(() => {
    setOrigin(origin);
  }, [origin]);

  useEffect(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${origin}/${id}`
      });
      setCanEdit(
        user.role === 'admins:SUPER-ADMIN' ||
          data?.contact === user._id ||
          data?.contact?._id === user._id
      );
    } catch (e) {
      message(e);
    }
  }, [user, id, origin]);

  const getResource = useCallback(async () => {
    try {
      await getElementData(origin, id);
    } catch (e) {
      message(e);
    }
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sourceposts/${id}/calculation?refYear=${refYear}&selectedYear=${selectedYear}&model=${origin}`
      });
      setResults(data);
    } catch (e) {
      message(e);
    }
    setIsSchemaLoading(false);
  }, [refYear, selectedYear, origin, id]);

  useEffect(() => {
    setIsSchemaLoading(true);
    (async () => {
      if (mode === 'directSave') {
        checkIfDemiRameIsAloneForLinkOut();
        setShowCreateSchema(false);
        await getResource();
      }
    })();
    setIsSchemaLoading(false);
  }, [getResource, mode]);

  useEffect(async () => {
    const arr = [];
    for (let j = 0; j < schema.transformers.length; j += 1) {
      for (let i = 0; i < schema.transformers[j].children.length; i += 1) {
        const data = await getATforDR(schema.transformers[j].children[i].id);
        if (data) arr.push(data);
      }
    }
    setAutoTRArray(arr.flat());
  }, [schema.transformers]);

  const showSchema = showCreateSchema ? (
    <>
      {canEdit && <Toolbar AutoTRArray={AutoTRArray} />}
      <DisplaySchema canEdit={canEdit} AutoTRArray={AutoTRArray} />
      <LinkOutModal />
    </>
  ) : (
    <Col span={24}>
      <TransformersSchema
        canEdit={canEdit}
        results={results}
        getResource={getResource}
        isSchemaLoading={isSchemaLoading}
      />
    </Col>
  );

  return (
    <Row className="schema-wrapper">
      <Row className="schema-header">
        {origin !== 'projects' && (
          <Switch
            checkedChildren={t(`schema.switch.${showCreateSchema}`)}
            unCheckedChildren={t(`schema.switch.${showCreateSchema}`)}
            onChange={() => {
              setShowCreateSchema(!showCreateSchema);
              setSwitchAlreadyUsed(true);
            }}
          />
        )}
      </Row>
      {showSchema}
    </Row>
  );
};
export default Schema;

Schema.propTypes = {
  id: PropTypes.string,
  origin: PropTypes.string.isRequired
};

Schema.defaultProps = {
  id: undefined
};
