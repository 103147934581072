import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ListResource } from '../../components/ListResource/ListResource';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { routes } from '../../utils/constants/adminRoutes';
import { useFormatter } from '../../utils/formatter';

const ListResearch = () => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [researchEnums, setResearchEnums] = useState({});

  const getResearchEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/research/enums`
      });
      setResearchEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getResearchEnums();
    })();
  }, []);

  const headers = [
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'sourcePost',
      key: 'sourcePost.name'
    },
    {
      label: 'created_at',
      key: 'created_at'
    },
    {
      label: 'contact',
      key: 'contact.first_name'
    },
    {
      label: 'contact',
      key: 'contact.last_name'
    }
  ];

  const columns = [
    {
      title: t('research.column.status'),
      key: 'status',
      dataIndex: 'status',
      filters: (researchEnums?.status || []).map((p) => ({
        text: t(`research.form.enums.${p}`),
        value: p
      })),
      render: (status) => t(`research.form.enums.${status}`),
      sorter: true
    },
    {
      title: t('research.column.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('research.column.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: (sourcePost) => (
        <Link
          to={{
            pathname: `${routes.SOURCEPOSTS}/show/${sourcePost?._id}`
          }}
        >
          {sourcePost?.name}
        </Link>
      ),
      sorter: true
    },
    {
      title: t('research.column.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) =>
        (created_at && moment(created_at).format('DD/MM/YYYY')) || '-',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="research"
      populate="sourcePost,contact"
      columns={columns}
      headers={headers}
      formatter={formattedData}
      canEditOrDeleteFct={(sourcePost) =>
        user.role === 'admins:SUPER-ADMIN' ||
        sourcePost?.contact?._id === user?._id
      }
      resourceModelName="research"
    />
  );
};

export default ListResearch;
