import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Row, Col } from 'antd';
import { useFormatter } from '../../utils/formatter';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ListResource } from '../../components/ListResource/ListResource';
import useImportContext from './ImportContext';

const { Option } = Select;

const ListImport = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { year, setYear } = useImportContext();
  const { formattedData } = useFormatter();
  const [sourcePosts, setSourcePosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSP, setSelectedSP] = useState();
  const [selectedYears, setSelectedYears] = useState([]);

  const getSourcePosts = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/sourceposts?populate=departData`
      });
      setSourcePosts(data);
      setSelectedSP(data[0]._id);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const getSelectedYears = () => {
    const selectedSourcePost = sourcePosts.find(
      (sourcePost) => sourcePost._id === selectedSP
    );
    if (selectedSourcePost?.departData) {
      const years = [];
      selectedSourcePost?.departData.forEach((depart) => {
        if (depart?.IAnormalImports) {
          (Object.keys(depart?.IAnormalImports) || []).map(
            (date) => !years.includes(date) && years.push(date)
          );
        }
        if (depart?.IinitImports) {
          (Object.keys(depart?.IinitImports) || []).map(
            (date) => !years.includes(date) && years.push(date)
          );
        }
      });
      setSelectedYears(years.sort());
    }
  };

  useEffect(() => {
    (async () => {
      await getSourcePosts();
    })();
  }, []);

  useEffect(() => {
    getSelectedYears();
  }, [selectedSP]);

  const headers = [
    {
      label: 'sourcePost',
      key: 'sourcePost.name'
    },
    {
      label: 'title',
      key: 'title'
    },
    {
      label: 'Iinit',
      key: 'Iinit'
    },
    {
      label: 'IAnormal',
      key: 'IAnormal'
    },
    {
      label: 'year',
      key: 'year'
    }
  ];

  const columns = [
    {
      title: t('sourceposts.show.departData.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: ({ name }) => name,
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.cell'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.IAnormal'),
      key: 'IAnormalImports',
      dataIndex: 'IAnormalImports',
      render: (IAnormalImports) =>
        IAnormalImports ? IAnormalImports[year] || '' : '',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.Iinit'),
      key: 'IinitImports',
      dataIndex: 'IinitImports',
      render: (IinitImports) => (IinitImports ? IinitImports[year] || '' : ''),
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.year'),
      key: 'year',
      dataIndex: 'year',
      render: () => year && year
    }
  ];
  return sourcePosts.length ? (
    <ListResource
      withCreateButton={false}
      showAction={false}
      deleteAction={false}
      editAction={false}
      extraQuery={`sourcePost=${selectedSP || sourcePosts[0]?._id}`}
      resourceName="departs"
      columns={columns}
      headers={headers}
      populate="sourcePost"
      resourceModelName="Depart"
      formatter={formattedData}
      customActionColumn
      extraButtons={
        <Row gutter={[8, 0]}>
          <Col>
            <Select
              loading={isLoading}
              style={{ width: 250 }}
              placeholder={t('import.selectedSourcePost')}
              onSelect={(data) => {
                setSelectedSP(data);
              }}
            >
              {(sourcePosts || []).map((sp) => (
                <Option key={sp._id} value={sp._id}>
                  {sp.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              loading={isLoading}
              style={{ width: 250 }}
              placeholder={t('import.selectedYears')}
              onSelect={(data) => {
                setYear(data);
              }}
            >
              {(selectedYears || []).map((sy) => (
                <Option key={sy} value={sy}>
                  {sy}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      }
    />
  ) : (
    []
  );
};

export default ListImport;
