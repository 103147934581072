import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import { SourcePostContextProvider } from './SourcePostContext';
import ListSourcePost from './ListSourcePost';
import CreateUpdateSourcePost from './CreateUpdateSourcePost';
import ShowSourcePost from './ShowSourcePost/ShowSourcePost';
import CreateUpdateProjects from './ShowSourcePost/CreateUpdateProjects';
import CreateUpdateWeather from './ShowSourcePost/CreateUpdateWeather';
import CreateUpdateProducer from './ShowSourcePost/CreateUpdateProducer';
import CreateUpdateContractualRelief from './ShowSourcePost/CreateUpdateContractualRelief';
import UpdateElements from './ShowSourcePost/SchemaElements/UpdateElements';
import ShowProject from './ShowSourcePost/ShowProject';

export const SourcePostRouter = () => (
  <SourcePostContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateSourcePost purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateSourcePost purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowSourcePost />} />
      <Route index element={<ListSourcePost />} />
      <Route
        path={`/${subRoutes.PROJECTS}/create`}
        element={<CreateUpdateProjects purpose="create" model="sourceposts" />}
      />
      <Route
        path={`/${subRoutes.PROJECTS}/edit/:id`}
        element={<CreateUpdateProjects purpose="edit" model="sourceposts" />}
      />
      <Route
        path={`/${subRoutes.WEATHER}/create`}
        element={<CreateUpdateWeather purpose="create" model="sourceposts" />}
      />
      <Route
        path={`/${subRoutes.WEATHER}/edit/:id`}
        element={<CreateUpdateWeather purpose="edit" model="sourceposts" />}
      />
      <Route
        path={`/${subRoutes.PRODUCER}/edit/:id`}
        element={<CreateUpdateProducer purpose="edit" model="sourceposts" />}
      />
      <Route
        path={`/${subRoutes.PRODUCER}/create`}
        element={<CreateUpdateProducer purpose="create" model="sourceposts" />}
      />
      <Route
        path={`/${subRoutes.CONTRACTUALRELIEF}/create`}
        element={
          <CreateUpdateContractualRelief purpose="create" model="sourceposts" />
        }
      />
      <Route
        path={`/${subRoutes.CONTRACTUALRELIEF}/edit/:id`}
        element={
          <CreateUpdateContractualRelief purpose="edit" model="sourceposts" />
        }
      />
      <Route
        path={`/${subRoutes.TRANSFORMER}/edit/:id`}
        element={
          <UpdateElements
            purpose="edit"
            element="transformer"
            model="sourceposts"
          />
        }
      />
      <Route
        path={`/${subRoutes.COLONNE}/edit/:id`}
        element={
          <UpdateElements
            purpose="edit"
            element="colonne"
            model="sourceposts"
          />
        }
      />
      <Route
        path={`/${subRoutes.DEMIRAME}/edit/:id`}
        element={
          <UpdateElements
            purpose="edit"
            element="demirame"
            model="sourceposts"
          />
        }
      />
      <Route
        path={`/${subRoutes.DEPART}/edit/:id`}
        element={
          <UpdateElements purpose="edit" element="depart" model="sourceposts" />
        }
      />
      <Route
        path={`/${subRoutes.PROJECTS}/show/:id`}
        element={<ShowProject model="sourceposts" />}
      />
    </Routes>
  </SourcePostContextProvider>
);
