import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import ListResearch from './ListResearch';
import ShowResearch from './ShowResearch';
import CreateUpdateResearch from './CreateUpdateResearch';
import CreateUpdateProjects from '../sourcePost/ShowSourcePost/CreateUpdateProjects';
import CreateUpdateWeather from '../sourcePost/ShowSourcePost/CreateUpdateWeather';
import CreateUpdateProducer from '../sourcePost/ShowSourcePost/CreateUpdateProducer';
import CreateUpdateContractualRelief from '../sourcePost/ShowSourcePost/CreateUpdateContractualRelief';
import { ResearchContextProvider } from './ResearchContext';
import ShowProject from '../sourcePost/ShowSourcePost/ShowProject';
import UpdateElements from '../sourcePost/ShowSourcePost/SchemaElements/UpdateElements';

export const ResearchRouter = () => (
  <ResearchContextProvider>
    <Routes>
      <Route index element={<ListResearch />} />
      <Route path="/show/:id" element={<ShowResearch />} />
      <Route
        path="/create"
        element={<CreateUpdateResearch purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateResearch purpose="edit" />}
      />
      <Route
        path={`/${subRoutes.PROJECTS}/create`}
        element={<CreateUpdateProjects purpose="create" model="research" />}
      />
      <Route
        path={`/${subRoutes.PROJECTS}/edit/:id`}
        element={<CreateUpdateProjects purpose="edit" model="research" />}
      />
      <Route
        path={`/${subRoutes.PROJECTS}/show/:id`}
        element={<ShowProject model="research" />}
      />
      <Route
        path={`/${subRoutes.WEATHER}/create`}
        element={<CreateUpdateWeather purpose="create" model="research" />}
      />
      <Route
        path={`/${subRoutes.WEATHER}/edit/:id`}
        element={<CreateUpdateWeather purpose="edit" model="research" />}
      />
      <Route
        path={`/${subRoutes.PRODUCER}/edit/:id`}
        element={<CreateUpdateProducer purpose="edit" model="research" />}
      />
      <Route
        path={`/${subRoutes.PRODUCER}/create`}
        element={<CreateUpdateProducer purpose="create" model="research" />}
      />
      <Route
        path={`/${subRoutes.CONTRACTUALRELIEF}/create`}
        element={
          <CreateUpdateContractualRelief purpose="create" model="research" />
        }
      />
      <Route
        path={`/${subRoutes.CONTRACTUALRELIEF}/edit/:id`}
        element={
          <CreateUpdateContractualRelief purpose="edit" model="research" />
        }
      />
      <Route
        path={`/${subRoutes.TRANSFORMER}/edit/:id`}
        element={
          <UpdateElements
            purpose="edit"
            element="transformer"
            model="research"
          />
        }
      />
      <Route
        path={`/${subRoutes.COLONNE}/edit/:id`}
        element={
          <UpdateElements purpose="edit" element="colonne" model="research" />
        }
      />
      <Route
        path={`/${subRoutes.DEMIRAME}/edit/:id`}
        element={
          <UpdateElements purpose="edit" element="demirame" model="research" />
        }
      />
      <Route
        path={`/${subRoutes.DEPART}/edit/:id`}
        element={
          <UpdateElements purpose="edit" element="depart" model="research" />
        }
      />
    </Routes>
  </ResearchContextProvider>
);
