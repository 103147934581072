import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components/ListResource/ListResource';
import { projectStatus } from '../../utils/constants/tagColors';
import { useFormatter } from '../../utils/formatter';

const { Option } = Select;

const ListD12Transfert = ({
  projectEnums,
  sourcePosts,
  selectedSP,
  setSelectedSP,
  isLoading,
  yearsColumn,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();

  const headers = [
    {
      label: 'ebr',
      key: 'ebr'
    },
    {
      label: 'department',
      key: 'department'
    },
    {
      label: 'codeGdo',
      key: 'codeGdo'
    },
    {
      label: 'department_impacted',
      key: 'department_impacted'
    },
    {
      label: 'gdo_htb_impacted',
      key: 'gdo_htb_impacted'
    },
    {
      label: 'case_identifier',
      key: 'case_identifier'
    },
    {
      label: 'case_status',
      key: 'case_status'
    },
    {
      label: 'yearImp',
      key: 'yearImp'
    },
    {
      label: 'projectPowerFois',
      key: 'projectPowerFois'
    },
    {
      label: 'comment',
      key: 'comment'
    }
  ];

  const columns = [
    {
      title: t('D12.column.EBR'),
      key: 'ebr',
      dataIndex: 'ebr',
      sorter: true
    },
    {
      title: t('D12.column.department'),
      key: 'department',
      dataIndex: 'department',
      sorter: true
    },
    {
      title: t('D12.column.codeGdo'),
      key: 'codeGdo',
      dataIndex: 'codeGdo',
      render: (codeGdo, data) => `${codeGdo}-${data.voltage}`,
      sorter: true
    },
    {
      title: t('D12.column.department_impacted'),
      key: 'department_impacted',
      dataIndex: 'department_impacted',
      sorter: true
    },
    {
      title: t('D12.column.gdo_htb_impacted'),
      key: 'gdo_htb_impacted',
      dataIndex: 'gdo_htb_impacted',
      sorter: true
    },
    {
      title: t('D12.column.case_identifier'),
      key: 'case_identifier',
      dataIndex: 'case_identifier',
      sorter: true
    },
    {
      title: t('projects.column.case_status'),
      key: 'case_status',
      dataIndex: 'case_status',
      render: (case_status) => (
        <Tag color={projectStatus[case_status]}>
          {t(`projects.form.enums.${case_status}`)}
        </Tag>
      ),
      filters: (projectEnums?.caseStatus || []).map((c) => ({
        text: t(`projects.form.enums.${c}`),
        value: c
      })),
      sorter: true
    },
    ...(yearsColumn || []),
    {
      title: t('D12.column.comment'),
      key: 'comment',
      dataIndex: 'changes',
      render: (changes) => changes && changes.comment,
      sorter: true
    }
  ];
  return sourcePosts.length ? (
    <ListResource
      resourceName="D12_transfert"
      dataToFetch="D12"
      populate="sourcePost.departData transformerData"
      extraQuery={`status=YES&case_status!=IN_PROGRESS&sourcePost=${selectedSP}&typeOfCharge=TRF`}
      columns={columns}
      headers={headers}
      resourceModelName="D12"
      editAction={false}
      showAction={false}
      withCreateButton={false}
      withImportButton={false}
      exportUrl="D12"
      deleteAction={false}
      customActionColumn
      forceRefresh={forceRefresh}
      formatter={formattedData}
      extraButtons={
        <Row gutter={[8, 0]}>
          <Col>
            <Select
              loading={isLoading}
              style={{ width: 250 }}
              placeholder={t('import.selectedSourcePost')}
              onSelect={(data) => {
                setSelectedSP(data);
              }}
              defaultValue={selectedSP}
            >
              {(sourcePosts || []).map((sp) => (
                <Option key={sp._id} value={sp._id}>
                  {sp.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      }
    />
  ) : (
    []
  );
};

export default ListD12Transfert;

ListD12Transfert.propTypes = {
  projectEnums: PropTypes.arrayOf({
    caseStatus: PropTypes.arrayOf({})
  }).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  sourcePosts: PropTypes.arrayOf({}),
  selectedSP: PropTypes.string,
  setSelectedSP: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yearsColumn: PropTypes.arrayOf({}).isRequired
};

ListD12Transfert.defaultProps = {
  sourcePosts: [],
  selectedSP: null
};
