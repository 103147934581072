import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components/ListResource/ListResource';

const Producer = ({ id, origin }) => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'sourcePost',
      key: 'sourcePost.name'
    },
    {
      label: 'depart',
      key: 'depart.title'
    },
    {
      label: 'contractNumber',
      key: 'contractNumber'
    },
    {
      label: 'powerInjected',
      key: 'powerInjected'
    },
    {
      label: 'intensity',
      key: 'intensity'
    },
    {
      label: 'yearMES',
      key: 'yearMES'
    }
  ];

  const columns = [
    {
      title: t('projects.column.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: ({ name }) => name,
      sorter: true
    },
    {
      title: t('producer.column.depart'),
      dataIndex: 'depart',
      render: (depart) => depart?.title || '-',
      sorter: true
    },
    {
      title: t('producer.column.contractNumber'),
      key: 'contractNumber',
      dataIndex: 'contractNumber',
      sorter: true
    },
    {
      title: t('producer.column.powerInjected'),
      key: 'powerInjected',
      dataIndex: 'powerInjected',
      sorter: true
    },
    {
      title: t('producer.column.intensity'),
      key: 'intensity',
      dataIndex: 'intensity',
      sorter: true
    },
    {
      title: t('producer.form.yearMES'),
      key: 'yearMES',
      render: (yearMES) => (yearMES && moment(yearMES).format('YYYY')) || '-',
      dataIndex: 'yearMES',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="producer"
      populate="sourcePost,depart"
      extraQuery={`sourcePost=${id}&model=${origin}`}
      columns={columns}
      headers={headers}
      resourceModelName="Producer"
      showTitle={false}
      createUrl={`/${origin}/producer`}
      path={`/${origin}/producer`}
      showAction={false}
    />
  );
};

export default Producer;

Producer.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired
};
