import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';

const ModificationModal = ({ modifiedData, modalOpen, closeModal }) => {
  const { t } = useTranslation();
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  return (
    <Modal
      title={modifiedData.title}
      centered
      open={modalOpen}
      width={850}
      onCancel={() => {
        closeModal();
      }}
      footer={false}
    >
      <Row justify="space-between">
        <Col span={12}>
          <Card title={t('history.modal.col_title1')} bordered={false}>
            {Object.keys(modifiedData?.newValue || [])
              .filter((f) => f !== 'historic')
              .map((value) => (
                <Row key={value._id}>
                  <Col span={14}>
                    {`${t(
                      `${modifiedData.modelName.toLowerCase()}.form.${value}`
                    )}:`}
                  </Col>
                  <Col span={10}>
                    {regex.test(modifiedData?.oldValue[value])
                      ? moment(modifiedData?.oldValue[value]).format('YYYY')
                      : modifiedData?.oldValue[value] || '-'}
                  </Col>
                </Row>
              ))}
          </Card>
        </Col>
        <Col span={12}>
          <Card title={t('history.modal.col_title2')} bordered={false}>
            {Object.keys(modifiedData?.newValue || [])
              .filter((f) => f !== 'historic')
              .map((value) => (
                <Row key={value._id}>
                  <Col span={14}>
                    {`${t(
                      `${modifiedData.modelName.toLowerCase()}.form.${value}`
                    )}:`}
                  </Col>
                  <Col span={10}>
                    {regex.test(modifiedData?.newValue[value])
                      ? moment(modifiedData?.newValue[value]).format('YYYY')
                      : modifiedData?.newValue[value] || '-'}
                  </Col>
                </Row>
              ))}
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModificationModal;

ModificationModal.propTypes = {
  modifiedData: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
