/* eslint-disable no-plusplus */
export const checkWarningUpdate = (
  purpose,
  schema,
  transformersElement,
  inputName,
  color,
  setWarningText,
  setWarningColor,
  AutoTRArray,
  ATData,
  setwarningAT,
  setwarningAtData,
  editType
) => {
  const sameText = [];
  const duplicate = [];
  setWarningColor(false);
  setWarningText(false);
  setwarningAtData(false);
  setwarningAT(false);
  if (purpose === 'create') {
    (transformersElement || []).forEach((transformer) => {
      if (inputName === transformer.title) sameText.push(transformer.title);
      return sameText;
    });
    (schema.transformers || []).forEach((co) => {
      if (inputName === co.title) sameText.push(co.title);
      if (co.children) {
        co.children.forEach((ra) => {
          if (inputName === ra.title) sameText.push(ra.title);
          if (ra.children) {
            ra.children.forEach((de) => {
              if (inputName === de.title) sameText.push(de.title);
            });
          }
        });
      }
      return false;
    });
    if (!color) setWarningColor(true);
    if (!inputName || sameText.length) setWarningText(true);
    if (!color || !inputName || sameText.length) return false;
    if (editType === 'AT') {
      if (!ATData.demirame || !ATData.installedPower || !ATData.position) {
        setwarningAtData(true);
        return false;
      }
      if (ATData.demirame) {
        (AutoTRArray || []).forEach((at) => {
          if (
            at.demirame === ATData.demirame &&
            Number(at.position) === ATData.position
          ) {
            setwarningAT(true);
            duplicate.push(at.name);
          }
        });
      }
      if (duplicate.length) return false;
    }
    return true;
  }
  return true;
};

export const formatNum = (num, unity) => {
  if (num === undefined || num === null || num === '') return 'NA';
  if (Math.abs(num) > 1000000) return `${(num / 1000000).toFixed(2)}M`;
  if (Math.abs(num) > 1000) return `${(num / 1000).toFixed(2)}k`;
  if (typeof num === 'number') return `${num.toFixed(2)} ${unity || ''}`;
  return num;
};

export const handleData = (id, type, mode, totals, projection) => {
  let filtered;
  if (projection && !totals[0]?.power) {
    filtered = totals?.filter((data) => id === data.depart) || [];
  } else {
    filtered = totals?.filter((data) => id === data._id) || [];
  }
  if (filtered.length === 0) return 'Ptmb Data';
  if (mode === 'Ptmb') {
    switch (type) {
      case 'transit-total':
        return formatNum(filtered[0]?.totalItmbInit, 'A') || 'NA';
      case 'puissance-total':
        return formatNum(filtered[0]?.totalStmbInit) || 'NA';
      case 'percent':
        return `${
          formatNum(
            // eslint-disable-next-line no-unsafe-optional-chaining
            filtered[0]?.power * 100
          ) || 'NA'
        } %`;
      case 'transit':
        return formatNum(filtered[0]?.ITmbPostProj) || 'NA';
      case 'puissance':
        return formatNum(filtered[0]?.STmbPostProj) || 'NA';
      case 'tension':
        return formatNum(filtered[0]?.voltage) || 'NA';
      default:
        return 'Ptmb Data';
    }
  }
  switch (type) {
    case 'transit-total':
      return formatNum(filtered[0]?.totalIStarMaxProj, 'A') || 'NA';
    case 'puissance-total':
      return formatNum(filtered[0]?.totalSStarMaxProj) || 'NA';
    case 'percent':
      return `${formatNum((filtered[0]?.powerMax || 0) * 100) || 'NA'} %`;
    case 'transit':
      return formatNum(filtered[0]?.IStarMaxPostProj) || 'NA';
    case 'puissance':
      return formatNum(filtered[0]?.SStarMaxPostProj) || 'NA';
    case 'tension':
      return formatNum(filtered[0]?.voltage) || 'NA';
    default:
      return 'Ptmb Data';
  }
};

export const getDRData = (tableData) => {
  const transit =
    (tableData &&
      tableData.reduce((acc, data) => acc + (data?.ITmbPostProj || 0), 0)) ||
    0;
  const puissance =
    (tableData &&
      tableData.reduce((acc, data) => acc + (data?.STmbPostProj || 0), 0)) ||
    0;
  const tension = (tableData && tableData[0]?.voltage) || 0;
  return { transit, puissance, tension };
};

export const getDRMaxData = (tableData) => {
  const transitMax =
    (tableData &&
      tableData.reduce(
        (acc, data) => acc + (data?.IStarMaxPostProj || 0),
        0
      )) ||
    0;
  const puissanceMax =
    (tableData &&
      tableData.reduce(
        (acc, data) => acc + (data?.SStarMaxPostProj || 0),
        0
      )) ||
    0;

  const tensionMax = (tableData && tableData[0]?.voltage) || 0;
  return { transitMax, puissanceMax, tensionMax };
};

export const canSwitch = (column) => {
  const childLen = column?.children?.length || 0;
  if (childLen === 0) return false;
  const nbSwitchTrue = column.children.reduce((acc, dr) => {
    let toAdd = 0;
    if (dr.electric_switch_in) toAdd++;
    if (dr.electric_switch_out) toAdd++;
    if (dr.electric_switch_between) toAdd++;
    return acc + toAdd;
  }, 0);
  const nbSwitch = Math.max(childLen * 3 - 1, 0);
  if (nbSwitchTrue === nbSwitch) return false;
  if (childLen === 1) {
    return !(
      column.children[0].electric_switch_in &&
      column.children[0].electric_switch_out
    );
  }
  return true;
};
