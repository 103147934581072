/* eslint-disable react/prop-types */
import React from 'react';
import { Skeleton, Row, Col, Typography, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { SourcePostList } from './listContent';
import { ContentCustom, DescriptionList } from '../../../components';
import Elements from './SchemaElements/SchemaElements';
import WeatherData from './WeatherData';
import Producer from './Producer';
import ContractualRelief from './ContractualRelief';
import useSourcePostContext from '../SourcePostContext';

const { Title } = Typography;
const { TabPane } = Tabs;

const PrincipalSourcePostData = ({ sourcePost, isLoading, id, origin }) => {
  const { t } = useTranslation();
  const { subSelectedTab, setSubSelectedTab } = useSourcePostContext();

  const handleTabChange = (key) => {
    setSubSelectedTab(key);
  };

  const dataTabsContent = [
    {
      label: t(
        'sourceposts.show.sourceposts_information.tabs.electricity_weather'
      ),
      key: '1',
      children: <WeatherData id={id} origin={origin} />
    },
    {
      label: t('sourceposts.show.sourceposts_information.tabs.elements'),
      key: '2',
      children: <Elements id={id} origin={origin} />
    },
    {
      label: t('sourceposts.show.sourceposts_information.tabs.producers'),
      key: '3',
      children: <Producer id={id} origin={origin} />
    },
    {
      label: t(
        'sourceposts.show.sourceposts_information.tabs.contractual_relief'
      ),
      key: '4',
      children: <ContractualRelief id={id} origin={origin} />
    }
  ];

  return (
    <>
      <ContentCustom>
        <Row className="post-data-row">
          <Title level={4}>Informations principales</Title>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList
              data={SourcePostList(sourcePost || {}, t)}
              translate
            />
          </Skeleton>
        </Row>
      </ContentCustom>
      <ContentCustom>
        <Title level={4}>Informations secondaires</Title>
        <Row>
          <Col span={24}>
            <Tabs activeKey={subSelectedTab} onChange={handleTabChange}>
              {dataTabsContent.map((tab) => (
                <TabPane tab={tab.label} key={tab.key}>
                  {tab.children}
                </TabPane>
              ))}
            </Tabs>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

export default PrincipalSourcePostData;
