import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListD12 from './ListD12';
import ShowD12 from './ShowD12';

export const D12Router = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowD12 />} />
    <Route index element={<ListD12 />} />
  </Routes>
);
