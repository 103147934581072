import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ShowUser from './ShowUser';
import ListUsers from './ListUsers';

export const UserRouter = () => (
  <Routes>
    <Route index element={<ListUsers />} />
    <Route path="/show/:id" element={<ShowUser />} />
  </Routes>
);
