import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import TransformersData from './TransformerData';
import ColonnesData from './ColonneData';
import DemirameData from './DemirameData';
import DepartData from './DepartData';
import useSourcePostContext from '../../SourcePostContext';

const { TabPane } = Tabs;

const SchemaElements = ({ id, origin }) => {
  const { subSelectedElementTab, setSubSelectedElementTab } =
    useSourcePostContext();

  const handleTabChange = (key) => {
    setSubSelectedElementTab(key);
  };
  const tabsContent = [
    {
      label: `Transformateurs`,
      key: '1',
      children: <TransformersData id={id} origin={origin} />
    },
    {
      label: `Colonnes`,
      key: '2',
      children: <ColonnesData id={id} origin={origin} />
    },
    {
      label: `Demi-rames`,
      key: '3',
      children: <DemirameData id={id} origin={origin} />
    },
    {
      label: `Départs`,
      key: '4',
      children: <DepartData id={id} origin={origin} />
    }
  ];

  return (
    <Tabs
      tabPosition="left"
      activeKey={subSelectedElementTab}
      onChange={handleTabChange}
    >
      {tabsContent.map((tab) => (
        <TabPane tab={tab.label} key={tab.key}>
          {tab.children}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default SchemaElements;

SchemaElements.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired
};
