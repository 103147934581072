import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import { useAuthContext } from '../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateSourcePost = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();
  const { user } = useAuthContext();
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...(!isSuperAdmin ? { contact: user._id } : {})
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="sourceposts"
      resource="sourceposts"
      config={config}
    />
  );
};

CreateUpdateSourcePost.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateSourcePost;
