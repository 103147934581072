export const SourcePostList = (data) => {
  const { name, code } = data;

  return [
    {
      label: 'sourceposts.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'sourceposts.form.code',
      span: 1,
      content: code || '-'
    }
  ];
};
