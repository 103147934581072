import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import useFields from './weatherFields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateWeather = ({ purpose }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('postesSources');
  const { fields, isFieldsLoading } = useFields(id);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        yearMES: data.yearMES && moment(data.yearMES)
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="weatherdata"
      resource="weatherdata"
      config={config}
    />
  );
};

CreateUpdateWeather.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateWeather;
