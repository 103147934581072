/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { styles, colorsArray } from '../utils/constants/schema';

const ColorSelector = ({ color, setColor }) => {
  const toggleColor = (newColor) => {
    setColor(newColor);
  };

  return (
    <Row>
      <Col span={24} data-testid="color-tags">
        {colorsArray.map((colorItem) => (
          <div
            data-testid="color-tag"
            key={colorItem}
            style={{
              ...styles.colorTag,
              backgroundColor: colorItem,
              border: color === colorItem ? '2px solid black' : 'none'
            }}
            onClick={() => toggleColor(colorItem)}
          />
        ))}
      </Col>
    </Row>
  );
};
ColorSelector.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired
};

export default ColorSelector;
