/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Card } from 'antd';
import {
  DatabaseOutlined,
  EditOutlined,
  DeleteOutlined,
  ColumnHeightOutlined,
  ThunderboltOutlined,
  HomeOutlined,
  SplitCellsOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';
import { useSchemaContext } from './SchemaContext';
import { onDragEnd, onDragEndDE } from './TransformersElementUtils';

const TransformersElement = ({ AutoTRArray }) => {
  const {
    setPurpose,
    setInputName,
    setColor,
    setSelectedKeysInfo,
    setSelectedKeys,
    setEditType,
    origin,
    patchSchemaDeparts
  } = useSchemaContext();
  const {
    schema,
    setSchema,
    patchSchema,
    patchDemiRame,
    patchDeparts,
    transformersElement,
    deleteAT
  } = useSchemaContext();

  const [isDragged, setIsDragged] = useState(null);
  const [isDraggedDE, setIsDraggedDE] = useState(null);

  const findIndex = (key) => {
    const indexCO = parseFloat(key?.toString().charAt(2));
    const indexRA = parseFloat(key?.toString().slice(4));
    return { indexCO, indexRA };
  };

  const mooveDepart = async (selectedDepart, impactedDepart) => {
    const newSchema = { ...schema };
    const { indexCO, indexRA } = findIndex(selectedDepart.key);
    const newDemiRameChildren = [];
    const selectedDemiRame =
      newSchema.transformers[indexCO].children[indexRA].children;
    await Promise.all(
      (selectedDemiRame || []).map((depart) => {
        if (
          depart.key === selectedDepart.key ||
          depart.key === impactedDepart.key
        ) {
          if (depart.key === selectedDepart.key) {
            newDemiRameChildren.push({ ...depart, key: impactedDepart.key });
          }
          if (depart.key === impactedDepart.key) {
            newDemiRameChildren.push({ ...depart, key: selectedDepart.key });
          }
        } else {
          newDemiRameChildren.push(depart);
        }
        return newDemiRameChildren;
      })
    );
    newDemiRameChildren.sort(
      (a, b) =>
        parseFloat(a.key?.toString().slice(6)) -
        parseFloat(b.key?.toString().slice(6))
    );
    newSchema.transformers[indexCO].children[indexRA].children =
      newDemiRameChildren;

    setSchema({ ...newSchema });
    patchSchemaDeparts();
  };

  let typeOfPatch = 'TR';
  if (origin === 'projects') {
    typeOfPatch = 'PR';
  }
  if (origin === 'rescue') {
    typeOfPatch = 'RE';
  }
  if (origin === 'research') {
    typeOfPatch = 'RS';
  }
  if (origin === 'd12') {
    typeOfPatch = 'D1';
  }

  return (
    <div className="schema-card-wrapper">
      <Row style={{ flexWrap: 'nowrap' }}>
        {(transformersElement || []).map((transformer, index) => (
          <Card
            className="edit-transformer-card"
            key={transformer.title}
            bordered={false}
            title={
              <>
                <ThunderboltOutlined style={{ marginRight: 8 }} />
                <span>{transformer?.title}</span>
              </>
            }
            onDragOver={(e) => e.preventDefault()}
            extra={
              <EditOutlined
                onClick={() => {
                  setPurpose('edit');
                  setInputName(transformer.title);
                  setColor(transformer.color);
                  setSelectedKeysInfo({ node: { ...transformer } });
                  setSelectedKeys([index]);
                  setEditType('TR');
                }}
              />
            }
            style={{
              width: 150,
              backgroundColor: transformer.color,
              marginRight: 24
            }}
          />
        ))}
      </Row>
      <Row style={{ marginTop: 16, flexWrap: 'nowrap' }}>
        {(schema.transformers || []).map((column, index) => (
          <Card
            className="edit-column-card"
            key={column.title}
            bordered={false}
            title={
              <>
                <ColumnHeightOutlined style={{ marginRight: 8 }} />
                <span>{column?.title}</span>
              </>
            }
            onDragOver={(e) => e.preventDefault()}
            onDrop={() =>
              onDragEnd(
                index,
                isDraggedDE,
                schema,
                isDragged,
                patchDeparts,
                setSchema,
                patchSchema,
                patchDemiRame,
                setIsDragged,
                typeOfPatch
              )
            }
            extra={
              <EditOutlined
                onClick={() => {
                  setPurpose('edit');
                  setInputName(column.title);
                  setColor(column.color);
                  setSelectedKeysInfo({ node: { ...column } });
                  setSelectedKeys([0, index]);
                  setEditType('CO');
                }}
              />
            }
            headStyle={{
              backgroundColor: column.color
            }}
          >
            <div>
              {column &&
                column.children.map((ra, index2) => (
                  <div key={ra.id}>
                    {AutoTRArray?.filter(
                      (AT) =>
                        AT.demirame === column.children[index2]?.id &&
                        AT.position === (index2 === 1 ? '2' : '1')
                    )?.map((ATData) => (
                      <Card
                        key={ATData._id}
                        className="edit-AT-card"
                        bordered
                        headStyle={{
                          backgroundColor: ATData?.color
                        }}
                        title={
                          <>
                            <SplitCellsOutlined style={{ marginRight: 8 }} />
                            <span>{ATData?.name}</span>
                          </>
                        }
                        extra={
                          <DeleteOutlined
                            onClick={() => deleteAT(ATData._id)}
                          />
                        }
                      />
                    ))}
                    <Card
                      key={ra.title}
                      className="edit-demirame-card"
                      headStyle={{
                        backgroundColor: ra.color
                      }}
                      bordered={false}
                      draggable
                      onDragStart={() => {
                        setIsDragged(ra);
                      }}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={() =>
                        onDragEndDE(
                          index,
                          index2,
                          schema,
                          isDraggedDE,
                          setSchema,
                          patchSchema,
                          patchDeparts,
                          setIsDraggedDE,
                          typeOfPatch,
                          isDragged
                        )
                      }
                      title={
                        <>
                          <DatabaseOutlined style={{ marginRight: 8 }} />
                          <span>{ra?.title}</span>
                        </>
                      }
                    >
                      {ra?.children.map((depart, ind) => (
                        <Card
                          key={depart.title}
                          className="edit-depart-card"
                          style={{
                            backgroundColor: depart.color
                          }}
                          bordered={false}
                          draggable
                          onDragStart={() => {
                            setIsDraggedDE(depart);
                          }}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() =>
                            onDragEndDE(
                              index,
                              index2,
                              schema,
                              isDraggedDE,
                              setSchema,
                              patchSchema,
                              patchDeparts,
                              setIsDraggedDE,
                              typeOfPatch,
                              isDragged
                            )
                          }
                        >
                          <Row justify="space-between" align="middle">
                            <p>
                              <HomeOutlined
                                style={{ marginRight: 6, fontSize: 12 }}
                              />
                              <span>{depart?.title}</span>
                            </p>
                            <EditOutlined
                              style={{ marginLeft: 8 }}
                              onClick={() => {
                                setPurpose('edit');
                                setInputName(depart.title);
                                setColor(depart.color);
                                setSelectedKeysInfo({ node: { ...depart } });
                                setSelectedKeys([0, 0, index, ra.key]);
                                setEditType('DE');
                              }}
                            />
                          </Row>
                          <Row justify="end" style={{ marginTop: 8 }}>
                            {ind !== 0 && (
                              <ArrowUpOutlined
                                onClick={() =>
                                  mooveDepart(
                                    ra.children[ind],
                                    ra.children[ind - 1]
                                  )
                                }
                              />
                            )}
                            {ind !== ra?.children?.length - 1 && (
                              <ArrowDownOutlined
                                onClick={() =>
                                  mooveDepart(
                                    ra.children[ind],
                                    ra.children[ind + 1]
                                  )
                                }
                              />
                            )}
                          </Row>
                        </Card>
                      ))}
                    </Card>
                    {AutoTRArray?.filter(
                      (AT) =>
                        AT.demirame === column.children[index2]?.id &&
                        AT.position === (index2 === 1 ? '1' : '2')
                    )?.map((ATData) => (
                      <Card
                        key={ATData._id}
                        className="edit-AT-card"
                        bordered={false}
                        headStyle={{
                          backgroundColor: ATData?.color
                        }}
                        title={ATData?.name}
                        extra={
                          <DeleteOutlined
                            onClick={() => deleteAT(ATData._id)}
                          />
                        }
                      />
                    ))}
                  </div>
                ))}
            </div>
          </Card>
        ))}
      </Row>
    </div>
  );
};

export default TransformersElement;

TransformersElement.propTypes = {
  AutoTRArray: PropTypes.arrayOf(PropTypes.shape({}))
};

TransformersElement.defaultProps = {
  AutoTRArray: []
};
