import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Tabs, Button } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import ListD12Charge from './ListD12Charge';
import ListD12Transfert from './ListD12Transfert';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';

const ListD12 = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [sourcePosts, setSourcePosts] = useState([]);
  const [selectedSP, setSelectedSP] = useState();
  const [projectEnums, setProjectEnums] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [yearsColumn, setYearsColumn] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const generateYearsColumn = () => {
    const results = [];
    for (let i = 1; i <= 6; i += 1) {
      const year = moment().add(i, 'years').format('YYYY');
      results.push({
        title: `${year}`,
        key: year,
        dataIndex: 'changes',
        render: (changes, data) =>
          changes &&
          changes.projectPowerFois &&
          moment(data.yearImp).format('YYYY') === `${year}`
            ? changes.projectPowerFois
            : '_'
      });
    }
    setYearsColumn(results);
    setForceRefresh(!forceRefresh);
  };

  const items = [
    {
      key: '1',
      label: `Charge`,
      children: (
        <ListD12Charge
          projectEnums={projectEnums}
          sourcePosts={sourcePosts}
          selectedSP={selectedSP}
          setSelectedSP={setSelectedSP}
          isLoading={isLoading}
          yearsColumn={yearsColumn}
          forceRefresh={forceRefresh}
        />
      )
    },
    {
      key: '2',
      label: `Transfert`,
      children: (
        <ListD12Transfert
          projectEnums={projectEnums}
          sourcePosts={sourcePosts}
          selectedSP={selectedSP}
          setSelectedSP={setSelectedSP}
          isLoading={isLoading}
          yearsColumn={yearsColumn}
          forceRefresh={forceRefresh}
        />
      )
    }
  ];

  const getSourcePosts = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/sourceposts?populate=departData`
      });
      setSourcePosts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const getProjectEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/enums`
      });
      setProjectEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      generateYearsColumn();
      await getProjectEnums();
      await getSourcePosts();
    })();
  }, []);

  return (
    <ContentCustom>
      <Tabs
        type="card"
        size="large"
        defaultActiveKey="1"
        items={items}
        onChange={generateYearsColumn}
        tabBarExtraContent={
          <Button type="primary">{moment().format('YYYY')}</Button>
        }
      />
    </ContentCustom>
  );
};

export default ListD12;
