import React from 'react';
import { Select, Input } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

const useFields = (users, sourcePosts, purpose) => {
  const { user } = useAuthContext();
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['sourcePost'],
      rules: [{ required: true }],
      input: (
        <Select disabled={purpose === 'edit'}>
          {(sourcePosts || []).map((item) => (
            <Option key={item._id} value={item._id}>
              {`${item.name}`}
            </Option>
          ))}
        </Select>
      )
    },
    ...(!isSuperAdmin
      ? [
          {
            name: ['contact'],
            input: <Input defaultValue={user._id} />,
            hidden: true
          }
        ]
      : []),
    ...(isSuperAdmin
      ? [
          {
            name: ['contact'],
            input: (
              <Select>
                {(users || []).map((item) => (
                  <Option key={item._id} value={item._id}>
                    {`${item.first_name} ${item.last_name}`}
                  </Option>
                ))}
              </Select>
            ),
            rules: [{ required: true }]
          }
        ]
      : []),
    {
      name: ['comment']
    }
  ];

  return {
    fields
  };
};

export default useFields;
