import React from 'react';
import { InputNumber, Select, DatePicker } from 'antd';

const { Option } = Select;

const useFields = (id, departs) => {
  const fields = [
    {
      name: ['contractNumber'],
      rules: [{ required: true }]
    },
    {
      name: ['powerInjected'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['intensity'],
      input: <InputNumber disabled />
    },
    {
      name: ['sourcePost'],
      rules: [{ required: true }],
      initialValue: id,
      hidden: true
    },
    {
      name: ['depart'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(departs || []).map((de) => (
            <Option key={de._id} value={de._id}>
              {de.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['yearMES'],
      rules: [{ required: true }],
      input: <DatePicker picker="year" />
    }
  ];

  return {
    fields
  };
};

export default useFields;
