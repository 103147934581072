import React from 'react';
import { Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';

const userList = (data, t) => {
  const { first_name, last_name, role, position, email, phone_number } =
    data || {};

  return [
    {
      label: 'users.form.role',
      span: 3,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'users.form.first_name',
      span: 2,
      content: first_name || '-'
    },
    {
      label: 'users.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 2,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.position',
      span: 1,
      content: position || '-'
    }
  ];
};

export default userList;
