import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const SourcePostContext = createContext({});

export const SourcePostContextProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [subSelectedTab, setSubSelectedTab] = useState();
  const [subSelectedElementTab, setSubSelectedElementTab] = useState();
  const [projectEnums, setProjectEnums] = useState({});
  const [users, setUsers] = useState([]);

  const getProjectEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/enums`
      });
      setProjectEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getProjectEnums();
      await getUsers();
    })();
  }, []);

  return (
    <SourcePostContext.Provider
      value={{
        message,
        dispatchAPI,
        selectedTab,
        setSelectedTab,
        subSelectedTab,
        setSubSelectedTab,
        projectEnums,
        users,
        subSelectedElementTab,
        setSubSelectedElementTab
      }}
    >
      {children}
    </SourcePostContext.Provider>
  );
};

SourcePostContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(SourcePostContext);
