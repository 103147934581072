export const updateTransformerTranslator = async ({
  setWarningText,
  setWarningColor,
  setwarningAT,
  setwarningAtData,
  editType,
  ATData,
  postAT,
  setPurpose,
  purpose,
  addToSchema,
  inputName,
  cellNumber,
  color,
  linkSwitchIn,
  linkSwitchBetween,
  updateSchema,
  selectedKeysInfo,
  getSourcePost,
  origin
}) => {
  setWarningText(false);
  setWarningColor(false);
  setwarningAT(false);
  setwarningAtData(false);
  if (editType === 'AT') {
    await postAT(ATData);
    setWarningColor(false);
    setWarningText(false);
    setwarningAT(false);
    setwarningAtData(false);
    setPurpose('init');
    getSourcePost(origin);
    return true;
  }
  setPurpose('init');
  switch (purpose) {
    case 'create':
      addToSchema({
        title: inputName,
        cell_number: cellNumber,
        color,
        type: editType,
        icon: editType,
        ...(editType !== 'TR'
          ? {
              children: []
            }
          : {}),
        ...(editType === 'TR'
          ? {
              link: []
            }
          : {}),
        ...(editType === 'RA'
          ? {
              electric_switch_in: true,
              electric_switch_out: true,
              electric_switch_between: false,
              link_switch_in: linkSwitchIn,
              link_switch_between: linkSwitchBetween
            }
          : {})
      });
      break;
    case 'edit':
      updateSchema({
        title: inputName,
        cell_number: cellNumber,
        color,
        type: selectedKeysInfo?.node?.type,
        icon: selectedKeysInfo?.node?.icon,
        id: selectedKeysInfo?.node?.id,
        children: selectedKeysInfo?.node?.children,
        ...(editType === 'RA'
          ? {
              electric_switch_in: true,
              electric_switch_out: true,
              electric_switch_between: false,
              link_switch_in: linkSwitchIn,
              link_switch_between: linkSwitchBetween
            }
          : {}),
        ...(editType === 'TR'
          ? {
              link: selectedKeysInfo?.node?.link
            }
          : {})
      });
      break;
    default:
      return true;
  }
  return true;
};
