import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const ImportContext = createContext({});

export const ImportContextProvider = ({ children }) => {
  const [year, setYear] = useState(moment().format('YYYY'));

  return (
    <ImportContext.Provider
      value={{
        year,
        setYear
      }}
    >
      {children}
    </ImportContext.Provider>
  );
};

ImportContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(ImportContext);
