/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

const Lines = ({ x, y, height = 4, width = 4, color }) => (
  <div
    data-testid="lines"
    style={{
      top: height < 0 ? y : y - height,
      left: width < 0 ? x : x - width,
      width: Math.abs(width),
      height: Math.abs(height),
      backgroundColor: color
    }}
    className="schema-line"
  />
);

export default Lines;

Lines.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string
};
