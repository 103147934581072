import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFields from './projectFields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateProjects = ({ purpose, model }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('postesSources');

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        yearMES: data.yearMES && moment(data.yearMES),
        yearTO: data.yearTO && moment(data.yearTO)
      })
    }
  };

  const { fields, isFieldsLoading } = useFields(t, id, model);

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      extraQuery={`model=${model}`}
      baseUrl="projects"
      resource="projects"
      config={config}
    />
  );
};

CreateUpdateProjects.propTypes = {
  purpose: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired
};

export default CreateUpdateProjects;
