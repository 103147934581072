import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { ListResource } from '../../components';

const ListDR = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'color',
      key: 'color'
    }
  ];

  const columns = [
    {
      title: t('regionaldirections.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('regionaldirections.form.color'),
      key: 'color',
      dataIndex: 'color',
      render: (color) => <Tag color={color}>{color}</Tag>,
      sorter: true
    }
  ];

  return (
    <ListResource
      resourceName="regionaldirections"
      columns={columns}
      headers={headers}
      resourceModelName="Regionaldirection"
    />
  );
};

export default ListDR;
