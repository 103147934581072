import React, { useCallback, useEffect, useState } from 'react';
import { Select, Input } from 'antd';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import useSourcePostContext from './SourcePostContext';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { users } = useSourcePostContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [regionalDirections, setRegionalDirections] = useState([]);
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const getDR = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/regionaldirections'
      });
      setRegionalDirections(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getDR();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['codeGdo'],
      rules: [{ required: true }]
    },
    {
      name: ['department'],
      rules: [{ required: true }]
    },
    {
      name: ['regionalDirection'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(regionalDirections || []).map((dr) => (
            <Option key={dr} value={dr._id}>
              {dr.name}
            </Option>
          ))}
        </Select>
      )
    },
    ...(!isSuperAdmin
      ? [
          {
            name: ['contact'],
            input: <Input defaultValue={user._id} />,
            hidden: true
          }
        ]
      : []),
    ...(isSuperAdmin
      ? [
          {
            name: ['contact'],
            input: (
              <Select loading={isFieldsLoading}>
                {(users || []).map((item) => (
                  <Option key={item._id} value={item._id}>
                    {`${item.first_name} ${item.last_name}`}
                  </Option>
                ))}
              </Select>
            ),
            rules: [{ required: true }]
          }
        ]
      : []),
    {
      name: ['comment']
    }
  ];

  return {
    fields
  };
};

export default useFields;
