import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListDR from './ListDR';
import CreateUpdateDR from './CreateUpdateDR';
import ShowDR from './ShowDR';

export const DRRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateDR purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateDR purpose="edit" />} />
    <Route path="/show/:id" element={<ShowDR />} />
    <Route index element={<ListDR />} />
  </Routes>
);
