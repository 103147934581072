import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Select, Table, Row, Col, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { ContentCustom } from '../../components';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import ModificationModal from './ModificationModal';

const { Option } = Select;

const ListHistory = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [sourcePostsIds, setSourcePostsIds] = useState([]);
  const [sourcePost, setSourcePost] = useState([]);
  const [id, setId] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const typeEnums = ['PATCH', 'CREATE', 'DELETE'];
  const modes = ['Sourcepost', 'Research', 'Project', 'Rescue'];
  const [currentMode, setCurrentMode] = useState(modes[0]);
  const [modifiedData, setModifiedData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const openModal = (data) => {
    setModifiedData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModifiedData({});
  };

  const patchPinHistoric = async (checked, data) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/history/pin/${id}?modelName=${currentMode}`,
        body: { ...data, pin: checked }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`;
    const monthCorrected = month.length < 2 ? `0${month}` : month;
    const day = `${d.getDate()}`;
    const year = d.getFullYear();

    return [day, monthCorrected, year].join('/');
  };

  const getSourcePosts = async (mode) => {
    try {
      const url = `/${mode}`;
      const { data } = await dispatchAPI('GET', {
        url
      });
      setSourcePostsIds(data.map((el) => ({ _id: el._id, name: el.name })));
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getSourcePosts('sourceposts');
  }, []);

  useEffect(async () => {
    try {
      if (!id) return;
      const { data } = await dispatchAPI('GET', {
        url: `/sourceposts/${id}?populate=historic.author`
      });
      const DRids = [];
      data?.schemaInit?.transformers?.forEach((element) =>
        element.children.forEach((DR) => {
          DRids.push(DR.id);
        })
      );
      const data2 = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const DRid of DRids) {
        const { data: DR } = await dispatchAPI('GET', {
          url: `/demirames/${DRid}?populate=historic.author`
        });
        data2.push(...(DR?.historic?.filter((el) => el.title) || []));
      }
      setSourcePost([...(data?.historic || []), ...data2]);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const replaceModes = (mode) => {
    switch (mode) {
      case 'Sourcepost':
        return 'Poste Source';
      case 'Research':
        return 'R&D';
      case 'Project':
        return 'Projet';
      case 'Rescue':
        return 'Secours';
      default:
        return '';
    }
  };

  useEffect(() => {
    switch (currentMode) {
      case 'Sourcepost':
        return getSourcePosts('sourceposts');
      case 'Research':
        return getSourcePosts('researches');
      case 'Project':
        return getSourcePosts('projects');
      case 'Rescue':
        return getSourcePosts('Rescues');
      default:
        return '';
    }
  }, [currentMode]);

  const sorter = (a, b, sortOrder, key) => {
    const keyArray = key.split('.');

    if (key === 'date') {
      const filteredA = new Date(a.date);
      const filteredB = new Date(b.date);
      return filteredA - filteredB;
    }
    let filteredA = a[keyArray[0]];
    let filteredB = b[keyArray[0]];
    if (keyArray.length > 1) {
      filteredA = filteredA[keyArray[1]];
      filteredB = filteredB[keyArray[1]];
    }
    if (sortOrder === 'ascend') {
      return filteredA.localeCompare(filteredB);
    }
    return -filteredA.localeCompare(filteredB);
  };

  const typeRenderer = (type) => {
    switch (type.toLowerCase()) {
      case 'create':
        return 'Création';
      case 'patch':
        return 'Modification';
      case 'delete':
        return 'Suppression';
      default:
        return '';
    }
  };

  const columns = [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'date'),
      render: (date) => formatDate(date),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'type'),
      render: (type) => typeRenderer(type),
      filters: typeEnums.map((ty) => ({
        text: t(`history.tags.${ty}`),
        value: ty
      }))
    },
    {
      title: 'Elements',
      key: 'modelName',
      dataIndex: 'modelName',
      render: (modelName) => t(`history.column.${modelName}`),
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'modelName')
    },
    {
      title: 'Détails',
      key: 'title',
      dataIndex: 'title',
      render: (title, data) =>
        title?.startsWith('Modification des données') ? (
          <Button onClick={() => openModal(data)} icon={<EyeOutlined />}>
            {title}
          </Button>
        ) : (
          `${title || '-'}`
        ),
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'details')
    },
    {
      title: 'Auteur',
      key: 'author',
      dataIndex: 'author',
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'author.first_name'),
      render: (author) => `${author.first_name} ${author.last_name}` || '-'
    },
    {
      title: 'Épingler',
      key: 'pin',
      dataIndex: 'pin',
      render: (pin, data) =>
        data && (
          <Switch
            disabled={!isSuperAdmin}
            checkedChildren="Oui"
            unCheckedChildren="Non"
            defaultChecked={pin}
            onChange={(checked) => patchPinHistoric(checked, data)}
          />
        )
    }
  ];

  return (
    <>
      <ModificationModal
        modifiedData={modifiedData}
        modalOpen={modalOpen}
        closeModal={closeModal}
      />
      <PageHeaderCustom title={t(`history.title`)} />
      <ContentCustom>
        <>
          <Row justify="space-between" align="middle">
            <Row gutter={[8, 0]}>
              {modes.map((mode) => (
                <Col>
                  <Button
                    key={mode}
                    type={currentMode === mode ? 'primary' : 'default'}
                    onClick={() => {
                      setId('');
                      setSourcePost([]);
                      setCurrentMode(mode);
                    }}
                  >
                    {replaceModes(mode)}
                  </Button>
                </Col>
              ))}
            </Row>
            <Select
              placeholder={t('history.show.selectedSourcePost')}
              style={{ width: 250 }}
              onSelect={(data) => {
                setId(data);
              }}
            >
              {(sourcePostsIds || []).map((y) => (
                <Option key={y._id} value={y._id}>
                  {y.name}
                </Option>
              ))}
            </Select>
          </Row>
          <Table
            columns={columns}
            dataSource={
              sourcePost
                ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                ?.map((el) => ({
                  ...el,
                  details: `${el.title} ${JSON.stringify(el?.newValue) || ''}`
                })) || []
            }
          />
        </>
      </ContentCustom>
    </>
  );
};

export default ListHistory;
