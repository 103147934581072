import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useAuthContext } from '../contexts/AuthContext';
import { modelName } from '../utils/constants/schema';

const SchemaContext = createContext({});

export const SchemaContextProvider = ({
  sourcePost,
  children,
  getSourcePost,
  yearInit
}) => {
  const initSchema = { transformers: [] };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('');
  const [origin, setOrigin] = useState('');
  const [schema, setSchema] = useState(initSchema);
  const [inputName, setInputName] = useState('');
  const [cellNumber, setCellNumber] = useState('');
  const [color, setColor] = useState('');
  const [transformersElement, setTransformersElement] = useState([]);
  const [dataTreeSchema, setDataTreeSchema] = useState([]);
  const [purpose, setPurpose] = useState('init');
  const [editType, setEditType] = useState('TR');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedKeysInfo, setSelectedKeysInfo] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(['0-0-0', '0-0-1']);
  const [currentStep, setCurrentStep] = useState(1);
  const [schemaRefreshed, setSchemaRefreshed] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [elementData, setElementData] = useState();
  const [linkSwitchIn, setLinkSwitchIn] = useState();
  const [linkSwitchBetween, setLinkSwitchBetween] = useState();
  const [linkSwitchInId, setLinkSwitchInId] = useState();
  const [linkSwitchBetweenId, setLinkSwitchBetweenId] = useState();
  const [linkSwitchInPreviousValue, setLinkSwitchInPreviousValue] = useState();
  const [linkSwitchInPreviousValueId, setLinkSwitchInPreviousValueId] =
    useState();
  const [refYear, setRefYear] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [showCreateSchema, setShowCreateSchema] = useState(false);
  const [switchAlreadyUsed, setSwitchAlreadyUsed] = useState(false);
  const [aloneDemiRame, setAloneDemiRame] = useState([]);
  const [openLinkOutModal, setOpenLinkOutModal] = useState(false);
  const [isSchemaLoading, setIsSchemaLoading] = useState(false);
  const [yearsImport, setYearsImport] = useState([]);
  const [yearsProject, setYearsProject] = useState([]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const getYearsImportAndProject = () => {
    const years = [moment().format('YYYY')];
    if (sourcePost?.departData) {
      (sourcePost?.departData || []).forEach((depart) => {
        if (depart?.IAnormalImports) {
          (Object.keys(depart?.IAnormalImports) || []).map(
            (date) => !years.includes(date) && years.push(date)
          );
        }
        if (depart?.IinitImports) {
          (Object.keys(depart?.IinitImports) || []).map(
            (date) => !years.includes(date) && years.push(date)
          );
        }
      });
      setYearsImport(years.sort());
      setRefYear(yearInit || Number(years.sort()[years.length - 1]));
      setSelectedYear(yearInit || Number(years.sort()[years.length - 1]));
    }
  };

  const resetStates = () => {
    setSchemaRefreshed(!schemaRefreshed);
    setCurrentStep(1);
    setSelectedKeys();
    setSelectedKeysInfo();
    setEditType('TR');
  };

  const findIndex = (key) => {
    const indexTR = parseFloat(key?.toString().charAt(0));
    const indexCO = parseFloat(key?.toString().charAt(2));
    const indexRA = parseFloat(key?.toString().slice(4));
    const indexDE = parseFloat(key?.toString().slice(6));
    return { indexTR, indexCO, indexRA, indexDE };
  };

  const findNextKey = ({ type }) => {
    const { indexCO, indexRA } = findIndex(selectedKeys);
    switch (type) {
      case 'TR':
        if (transformersElement.length) {
          const keys = transformersElement.map((item) => parseFloat(item.key));
          return `${(Math.max(...keys) + 1).toString()}`;
        }
        return '0';
      case 'CO':
        if (schema.transformers.length) {
          const keys = schema.transformers.map((item) =>
            parseFloat(item.key.split('-')[1])
          );
          return `0-${(Math.max(...keys) + 1).toString()}`;
        }
        return '0-0';
      case 'RA':
        if (schema.transformers[indexCO].children.length) {
          const keys = schema.transformers[indexCO].children.map((item) =>
            parseFloat(item.key.slice(-1))
          );
          return `${selectedKeys}-${(Math.max(...keys) + 1).toString()}`;
        }
        return `${selectedKeys}-0`;
      case 'DE':
        if (schema.transformers[indexCO].children[indexRA].children.length) {
          const keys = schema.transformers[indexCO].children[
            indexRA
          ].children.map((item) => parseFloat(item.key.slice(-1)));
          return `${selectedKeys}-${(Math.max(...keys) + 1).toString()}`;
        }
        return `${selectedKeys}-0`;
      default:
        return true;
    }
  };
  const manageLink = (key, linkIn, linkBetween, transformer, action) => {
    const newSchema = { ...schema };
    const { indexCO, indexRA } = findIndex(key);
    if (linkIn) {
      const newTranformer = transformersElement;
      const linkId = newTranformer[linkIn].id;
      if (action === 'add' || !linkSwitchInPreviousValue) {
        newTranformer[linkIn].link.push(transformer);
        if (mode === 'directSave') {
          newSchema.transformers[indexCO].children[indexRA].link_switch_in_id =
            linkId;
        }
      }
      if (
        action === 'update' &&
        linkSwitchInPreviousValue !== linkSwitchIn &&
        linkSwitchInPreviousValue
      ) {
        newTranformer[linkIn].link.push({ ...transformer, key });
        newTranformer[linkSwitchInPreviousValue].link = newTranformer[
          linkSwitchInPreviousValue
        ].link.filter((link) => link.key !== key);
      }
      newSchema.transformers[indexCO].children[indexRA].link_switch_in = linkIn;
      setTransformersElement(newTranformer);
    }
    if (linkBetween) {
      newSchema.transformers[indexCO].children[indexRA].link_switch_between =
        linkBetween;
      const otherLink =
        newSchema.transformers[parseFloat(linkBetween?.toString().charAt(2))]
          ?.children[parseFloat(linkBetween?.toString().slice(4))];
      if (otherLink) {
        newSchema.transformers[
          parseFloat(linkBetween?.toString().charAt(2))
        ].children[
          parseFloat(linkBetween?.toString().slice(4))
        ].link_switch_between = key;
      }
      if (mode === 'directSave' && otherLink) {
        const linkRaIn =
          newSchema.transformers[parseFloat(linkBetween?.toString().charAt(2))]
            .children[parseFloat(linkBetween?.toString().slice(4))].id;
        newSchema.transformers[indexCO].children[
          indexRA
        ].link_switch_between_id = linkRaIn;
        newSchema.transformers[
          parseFloat(linkBetween?.toString().charAt(2))
        ].children[
          parseFloat(linkBetween?.toString().slice(4))
        ].link_switch_between_id =
          newSchema.transformers[indexCO].children[
            indexRA
          ].link_switch_between_id;
      }
    }
    setSchema({ ...newSchema });
    setLinkSwitchIn();
    setLinkSwitchInPreviousValue();
    setLinkSwitchBetween();
  };

  const getSchemaData = (data) => {
    setSchema(data?.schemaInit);
    setTransformersElement(data?.transformersElement);
    setSchemaRefreshed(!schemaRefreshed);
    setMode('directSave');
  };

  const patchDemiRame = async (demiRame, colonneTo) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/demirame/${demiRame.id}`,
        body: {
          ...demiRame,
          change: `Ripage de la demi-rame ${demiRame.title} vers ${colonneTo}`
        }
      });
      if (!showCreateSchema && linkSwitchInId) {
        try {
          await dispatchAPI('PATCH', {
            url: `/transformers/${linkSwitchInId}`,
            body: {
              $addToSet: { demiRame: demiRame.id }
            }
          });
        } catch (error) {
          message.error(error);
        }
      }
      window.location.reload();
    } catch (error) {
      message.error(error);
    }
  };

  const patchDeparts = async (depart, demiRameFrom, demiRameTo) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/departs/${depart._id || depart.id}`,
        body: {
          ...depart,
          change: `Ripage du départ ${depart.title} de ${demiRameFrom} vers ${demiRameTo}`
        }
      });
    } catch (error) {
      message.error(error);
    }
  };

  const patchColumns = async (column) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/colonne/${column._id || column.id}`,
        body: {
          ...column
        }
      });
    } catch (error) {
      message.error(error);
    }
  };

  const patchSchemaDeparts = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/sourceposts/${id}`,
        body: {
          schemaInit: schema
        }
      });
      message.success(t('schema.message.patch'));
    } catch (error) {
      message.error(error);
    }
  };

  const patchSchema = async (type, action, objectToAdd, parentId) => {
    const { indexTR, indexCO, indexRA, indexDE } = findIndex(
      action === 'add' ? objectToAdd.key : selectedKeys
    );
    try {
      await dispatchAPI('PATCH', {
        url: `/schemas/${selectedKeysInfo?.node?.id}?modelName=${modelName[type]}&parentId=${parentId}`,
        body: {
          ...(type === 'SP' ? [] : schema),
          transformersElements: type === 'SP' ? [] : transformersElement,
          sourcePostId: id,
          modelToUpdate: objectToAdd
            ? {
                ...objectToAdd,
                ...(mode === 'directSave' && linkSwitchInId
                  ? {
                      link_switch_in_id: linkSwitchInId
                    }
                  : {}),
                ...(mode === 'directSave' && linkSwitchBetweenId
                  ? {
                      link_switch_between_id: linkSwitchBetweenId
                    }
                  : {})
              }
            : {
                ...selectedKeysInfo?.node,
                ...(mode === 'directSave' && linkSwitchInId
                  ? {
                      link_switch_in_id: linkSwitchInId
                    }
                  : {}),
                ...(mode === 'directSave' && linkSwitchBetweenId
                  ? {
                      link_switch_between_id: linkSwitchBetweenId
                    }
                  : {}),
                ...(mode === 'directSave' && linkSwitchInPreviousValueId
                  ? {
                      link_switch_in_previous_id: linkSwitchInPreviousValueId
                    }
                  : {})
              },
          action,
          indexTR,
          indexCO,
          indexRA,
          indexDE
        }
      });
      getSourcePost(origin);
      if (type === 'SP') navigate(-1);
      if (type === 'CO' && action !== 'add')
        patchColumns(
          objectToAdd || {
            ...selectedKeysInfo?.node,
            title: inputName,
            cell_number: cellNumber,
            color
          }
        );
      message.success(t('schema.message.patch'));
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
    setIsLoadingButton(false);
  };

  const patchDemiRameLinkOut = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/schemas/linkOut`,
        body: {
          transformers: schema.transformers,
          sourcePostId: id,
          demiRameToUpdate: aloneDemiRame
        }
      });
      getSourcePost(origin);
      message.success(t('schema.message.patch'));
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const deleteSchema = async (type) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/schemas/${
          type === 'SP' ? id : selectedKeysInfo?.node?.id
        }?modelName=${modelName[type]}&sourcePostId=${id}`
      });
      await patchSchema(type, 'delete');
      message.success(t('schema.message.delete'));
    } catch (e) {
      message(e);
    }
  };

  const addToSchema = (transformer) => {
    const newSchema = { ...schema };
    const key = findNextKey(transformer);
    const { indexCO, indexRA } = findIndex(selectedKeys);
    switch (editType) {
      case 'TR':
        setTransformersElement([
          ...transformersElement,
          { ...transformer, key }
        ]);
        break;
      case 'CO':
        newSchema.transformers = [
          ...newSchema.transformers,
          { ...transformer, key }
        ];
        break;
      case 'RA':
        newSchema.transformers[indexCO].children.push({
          ...transformer,
          key
        });
        manageLink(
          key,
          linkSwitchIn,
          linkSwitchBetween,
          {
            ...transformer,
            key
          },
          'add'
        );
        break;
      case 'DE':
        newSchema.transformers[indexCO].children[indexRA].children.push({
          ...transformer,
          key
        });
        break;
      default:
        return true;
    }
    if (mode === 'directSave') {
      patchSchema(
        editType,
        'add',
        {
          ...transformer,
          key
        },
        selectedKeysInfo?.node?.id
      );
    }
    setSchema({ ...newSchema });
    setLinkSwitchIn();
    setSchemaRefreshed(!schemaRefreshed);
    return true;
  };

  const deleteFromSchema = () => {
    const newSchema = { ...schema };
    const type = selectedKeysInfo?.node?.type;
    const { indexTR, indexCO, indexRA, indexDE } = findIndex(selectedKeys);
    switch (type) {
      case 'TR':
        newSchema.transformers.map((co, index) =>
          co.children.map((ra, i) => {
            if (ra.link_switch_in === selectedKeys[0].toString())
              newSchema.transformers[index].children[i].link_switch_in = '';
            return newSchema;
          })
        );
        transformersElement.splice(indexTR, 1);
        break;
      case 'CO':
        newSchema.transformers.splice(indexCO, 1);
        break;
      case 'RA':
        newSchema.transformers[indexCO].children.splice(indexRA, 1);
        if (selectedKeysInfo.node.link_switch_in) {
          const newTranformer = transformersElement;
          transformersElement.map((element, index) =>
            element.link.map((link) => {
              if (link.key === selectedKeysInfo.node.key)
                newTranformer[index].link = newTranformer[index].link.filter(
                  (l) => l.key !== selectedKeysInfo.node.key
                );
              return newTranformer;
            })
          );
          setTransformersElement(newTranformer);
        }

        break;
      case 'DE':
        newSchema.transformers[indexCO].children[indexRA].children.splice(
          indexDE,
          1
        );
        break;
      default:
        return true;
    }
    if (mode === 'directSave') {
      deleteSchema(type);
    }
    setSchema({ ...newSchema });
    setPurpose('init');
    resetStates();
    return true;
  };

  const updateSchema = async (transformer) => {
    const newSchema = { ...schema };
    const newTranformer = transformersElement;
    const { indexTR, indexCO, indexRA, indexDE } = findIndex(selectedKeys);
    const type = selectedKeysInfo?.node?.type;
    const key = selectedKeysInfo?.node?.key;
    switch (type) {
      case 'TR':
        transformersElement.map((item) => {
          if (item.key === key) {
            newTranformer[indexTR] = { ...transformer, key, type };
          }
          return newTranformer;
        });
        setTransformersElement(newTranformer);
        break;
      case 'CO':
        newSchema.transformers.map((item) => {
          if (item.key === key)
            newSchema.transformers[indexCO] = {
              ...transformer,
              key,
              type
            };
          return newSchema;
        });
        break;
      case 'RA':
        newSchema.transformers[indexCO].children.map((item) => {
          if (item.key === key)
            newSchema.transformers[indexCO].children[indexRA] = {
              ...transformer,
              key,
              type
            };
          return newSchema;
        });
        manageLink(key, linkSwitchIn, linkSwitchBetween, transformer, 'update');
        break;
      case 'DE':
        newSchema.transformers[indexCO].children[indexRA].children.map(
          (item) => {
            if (item.key === key)
              newSchema.transformers[indexCO].children[indexRA].children[
                indexDE
              ] = {
                ...transformer,
                key,
                type
              };
            return newSchema;
          }
        );
        break;
      default:
        return true;
    }
    if (mode === 'directSave') await patchSchema(type, 'edit');
    setSchema({ ...newSchema });
    resetStates();
    return true;
  };

  const postSchema = async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('POST', {
        url: `/schemas`,
        body: { ...schema, sourcePostId: id, transformersElement }
      });
      getSourcePost(origin);
      message.success(t('schema.message.saved'));
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
    setIsLoading(false);
  };

  const checkIfDemiRameIsAloneForLinkOut = (type) => {
    const ra = [];
    schema.transformers.forEach((column, index) => {
      if (column.children.length === 1) {
        if (
          !column.children[0]?.link_switch_out_id ||
          !sourcePost.demiRameData.find(
            (demiRame) => demiRame._id === column.children[0].id
          )?.link_switch_out_id
        )
          ra.push({ ...column.children[0], indexCO: index });
      }
    });
    if (ra.length) {
      setAloneDemiRame(ra);
      setOpenLinkOutModal(true);
    } else if (type === 'post') postSchema();
  };

  const getElementData = async (type, elementId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${type}/${elementId}`
      });
      setElementData(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (sourcePost?.schemaInit?.transformers?.length) {
      getSchemaData(sourcePost);
    }
    if (sourcePost?.transformersElement?.length) {
      setTransformersElement(sourcePost?.transformersElement);
      setMode('directSave');
    }
    getYearsImportAndProject();
  }, [sourcePost]);

  const postAT = async (ATData) => {
    try {
      await dispatchAPI('POST', {
        url: `/autotransformer`,
        body: ATData
      });
      message.success(t('schema.message.saved'));
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const getATforDR = async (DRid) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/autotransformer?demirame=${DRid}`
      });
      return data;
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
      return [];
    }
  };

  const deleteAT = async (ATid) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/autotransformer/${ATid}`
      });
      window.location.reload();
      message.success(t('schema.message.saved'));
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  useEffect(() => {
    const yearsSelected = [];
    if (refYear) {
      const referenceYear = refYear;
      for (let i = 0; i < 30; i += 1) {
        yearsSelected.push(Number(referenceYear) + Number(i));
      }
      setYearsProject(yearsSelected);
    }
  }, [refYear]);

  return (
    <SchemaContext.Provider
      value={{
        schema,
        setSchema,
        patchSchema,
        addToSchema,
        updateSchema,
        deleteFromSchema,
        patchDemiRame,
        patchDeparts,
        purpose,
        setPurpose,
        setEditType,
        editType,
        selectedKeys,
        setSelectedKeys,
        selectedKeysInfo,
        setSelectedKeysInfo,
        currentStep,
        inputName,
        setInputName,
        setCellNumber,
        cellNumber,
        color,
        setColor,
        setCurrentStep,
        schemaRefreshed,
        setSchemaRefreshed,
        expandedKeys,
        setExpandedKeys,
        isLoading,
        setDataTreeSchema,
        dataTreeSchema,
        mode,
        deleteSchema,
        modalVisible,
        setModalVisible,
        getElementData,
        elementData,
        transformersElement,
        linkSwitchIn,
        setLinkSwitchIn,
        linkSwitchBetween,
        setLinkSwitchBetween,
        setLinkSwitchInPreviousValue,
        setTransformersElement,
        setLinkSwitchInId,
        setLinkSwitchBetweenId,
        setLinkSwitchInPreviousValueId,
        refYear,
        setRefYear,
        selectedYear,
        setSelectedYear,
        showCreateSchema,
        setShowCreateSchema,
        checkIfDemiRameIsAloneForLinkOut,
        aloneDemiRame,
        openLinkOutModal,
        setOpenLinkOutModal,
        postSchema,
        patchDemiRameLinkOut,
        isSchemaLoading,
        setIsSchemaLoading,
        yearsImport,
        yearsProject,
        origin,
        setOrigin,
        postAT,
        getATforDR,
        id,
        deleteAT,
        setIsLoadingButton,
        isLoadingButton,
        patchSchemaDeparts,
        setSwitchAlreadyUsed,
        switchAlreadyUsed,
        getSourcePost
      }}
    >
      {children}
    </SchemaContext.Provider>
  );
};

SchemaContextProvider.propTypes = {
  getSourcePost: PropTypes.func.isRequired,
  sourcePost: PropTypes.shape({
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.number
      })
    ),
    departData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    schemaInit: PropTypes.shape({
      transformers: PropTypes.arrayOf(
        PropTypes.shape({ length: PropTypes.number }).isRequired
      )
    }),
    transformersElement: PropTypes.arrayOf(PropTypes.shape({})),
    demiRameData: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  yearInit: PropTypes.number
};

SchemaContextProvider.defaultProps = {
  sourcePost: null,
  yearInit: null
};

export const useSchemaContext = () => {
  const context = useContext(SchemaContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
