import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListImport from './ListImport';
import { ImportContextProvider } from './ImportContext';

export const ImportRouter = () => (
  <ImportContextProvider>
    <Routes>
      <Route index element={<ListImport />} />
    </Routes>
  </ImportContextProvider>
);
