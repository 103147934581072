import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, InputNumber, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import useSourcePostContext from '../SourcePostContext';
import useResearchContext from '../../research/ResearchContext';
import useRescueContext from '../../rescue/RescueContext';

const { Option } = Select;

const UpdateDepartProjectModal = ({
  dataToUpdate,
  isModalOpen,
  closeModal,
  patchChangesProject,
  sourcePosts,
  getLinkedDeparts,
  departsLinked,
  isLoading,
  isPatchLoading,
  purpose,
  getChangeImpactedId
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const sourcePostContext = useSourcePostContext();
  const researchContext = useResearchContext();
  const rescueContext = useRescueContext();
  const projectEnums =
    sourcePostContext?.projectEnums ||
    researchContext?.projectEnums ||
    rescueContext?.projectEnums;

  const onValidate = (values) => {
    const { depart } = dataToUpdate;
    patchChangesProject({ ...dataToUpdate, ...values }, depart);
    form.resetFields();
  };

  useEffect(() => {
    if (dataToUpdate) form.setFieldsValue(dataToUpdate);
  }, []);

  return (
    <Modal
      title={`${t(
        `projects.modal.title_${
          purpose === 'duplicate' ? 'duplicate' : 'update'
        }`
      )} ${dataToUpdate.title}`}
      centered
      open={isModalOpen}
      width={650}
      footer={false}
      onCancel={() => {
        form.resetFields();
        closeModal();
      }}
    >
      <Form form={form} onFinish={onValidate} {...formItemLayout}>
        <Form.Item
          {...tailFormItemLayout}
          name="projectPowerNonFois"
          label={t('projects.form.projectPowerNonFois')}
          initialValue={dataToUpdate.projectPowerNonFois}
          rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          {...tailFormItemLayout}
          name="projectPowerFois"
          label={t('projects.form.projectPowerFois')}
          initialValue={dataToUpdate.projectPowerFois}
          rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
        >
          <InputNumber disabled />
        </Form.Item>
        <Form.Item
          {...tailFormItemLayout}
          name="coefFois"
          label={t('projects.form.coefFois')}
          initialValue={dataToUpdate.coefFois}
          rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
        >
          <InputNumber />
        </Form.Item>
        {purpose === 'update' ? (
          <>
            <Form.Item
              {...tailFormItemLayout}
              name="typeOfCharge"
              label={t('projects.form.typeOfCharge')}
              initialValue={dataToUpdate.typeOfCharge}
              rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
            >
              <Select>
                {(projectEnums?.typeOfCharge || []).map((charge) => (
                  <Option key={charge} value={charge}>
                    {t(`projects.form.enums.${charge}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) =>
                getFieldValue('typeOfCharge') === 'TRF' && (
                  <>
                    <Form.Item
                      {...tailFormItemLayout}
                      name="postSource_impacted"
                      label={t(`projects.form.sourcePostId`)}
                      style={{ width: '100%' }}
                      initialValue={dataToUpdate.sourcePost_impacted}
                      rules={[
                        { required: true, message: t('errors.form.REQUIRED') }
                      ]}
                    >
                      <Select
                        disabled={!!dataToUpdate?.department_impacted}
                        allowClear
                        notFoundContent={null}
                        filterOption={false}
                        onChange={(value) => getLinkedDeparts(value)}
                      >
                        {sourcePosts.map((item) => (
                          <Select.Option key={item?._id} value={item?._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {dataToUpdate?.department_impacted && (
                      <Form.Item
                        {...tailFormItemLayout}
                        name="department_impacted"
                        label={t(`projects.form.projectId`)}
                        style={{ width: '100%' }}
                        initialValue={dataToUpdate.department_impacted}
                      >
                        <p>{dataToUpdate?.department_impacted_name}</p>
                      </Form.Item>
                    )}
                    <Form.Item
                      {...tailFormItemLayout}
                      name="depart_impacted"
                      label={t('projects.form.departId')}
                      style={{ width: '100%' }}
                      initialValue={dataToUpdate.depart_impacted}
                      rules={[
                        { required: true, message: t('errors.form.REQUIRED') }
                      ]}
                    >
                      {dataToUpdate.depart_impacted ? (
                        <p>{dataToUpdate?.depart_impacted_name}</p>
                      ) : (
                        <Select
                          allowClear
                          disabled={!!dataToUpdate?.depart_impacted}
                          notFoundContent={null}
                          filterOption={false}
                          loading={isLoading}
                          onChange={(value) => getChangeImpactedId(value)}
                        >
                          {departsLinked.map((item) => (
                            <Select.Option key={item?._id} value={item?._id}>
                              {item.title}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </>
                )
              }
            </Form.Item>
          </>
        ) : (
          []
        )}

        <Form.Item
          {...tailFormItemLayout}
          name="comment"
          label={t('projects.form.comment')}
          initialValue={dataToUpdate.comment}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{
            marginTop: 35
          }}
          {...tailFormItemLayout}
        >
          <Button type="primary" htmlType="submit" loading={isPatchLoading}>
            {t('buttons.save')}
            <CheckOutlined />
          </Button>
          <Button
            type="link"
            danger
            onClick={() => {
              form.resetFields();
              closeModal();
            }}
          >
            {t('buttons.cancel')}
            <CloseOutlined />
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDepartProjectModal;

UpdateDepartProjectModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  patchChangesProject: PropTypes.func.isRequired,
  getChangeImpactedId: PropTypes.func.isRequired,
  dataToUpdate: PropTypes.shape({
    title: PropTypes.string.isRequired,
    depart: PropTypes.string.isRequired,
    coefFois: PropTypes.number.isRequired,
    projectPowerNonFois: PropTypes.number.isRequired,
    projectPowerFois: PropTypes.number.isRequired,
    typeOfCharge: PropTypes.string.isRequired,
    department_impacted: PropTypes.string.isRequired,
    depart_impacted: PropTypes.string.isRequired,
    department_impacted_name: PropTypes.string.isRequired,
    depart_impacted_name: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    sourcePost_impacted: PropTypes.string.isRequired
  }),
  sourcePosts: PropTypes.arrayOf(),
  getLinkedDeparts: PropTypes.func.isRequired,
  departsLinked: PropTypes.arrayOf(),
  isLoading: PropTypes.bool.isRequired,
  isPatchLoading: PropTypes.bool.isRequired,
  purpose: PropTypes.string
};

UpdateDepartProjectModal.defaultProps = {
  dataToUpdate: undefined,
  sourcePosts: [],
  departsLinked: [],
  purpose: ''
};
