import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListArticles from './ListArticles';
import CreateUpdateArticle from './CreateUpdateArticle';
import ShowArticle from './ShowArticle';

export const ArticleRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateArticle purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateArticle purpose="edit" />} />
    <Route path="/show/:id" element={<ShowArticle />} />
    <Route index element={<ListArticles />} />
  </Routes>
);
