import React from 'react';
import { Select, Tag } from 'antd';
import { colorsRD } from '../../utils/constants/tagColors';

const { Option } = Select;

export const useFields = () => {
  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['color'],
      rules: [{ required: true }],
      input: (
        <Select>
          {colorsRD.map((color) => (
            <Option key={color.value} value={color.value}>
              <Tag color={color.value}>{color.name}</Tag>
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return {
    fields
  };
};
