import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Popconfirm, message } from 'antd';
import {
  PlusSquareOutlined,
  SaveOutlined,
  WarningOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSchemaContext } from './SchemaContext';
import {
  disabledButton,
  stepButtons,
  modelName
} from '../utils/constants/schema';
import { checkWarningUpdate } from './utils';
import ToolBarFormat from './ToolBarFormat';
import { ATContent } from './ToolbarElements/ToolbarATElements';
import {
  GeneralContent,
  ButtonsContent
} from './ToolbarElements/ToolbarGeneralElements';
import { updateTransformerTranslator } from './ToolbarElements/ToolbarUtils';
import { MixedContent } from './ToolbarElements/ToolbarMixedElements';

const Toolbar = ({ AutoTRArray }) => {
  const { t } = useTranslation();
  const {
    schema,
    purpose,
    setPurpose,
    setEditType,
    selectedKeysInfo,
    currentStep,
    setCurrentStep,
    editType,
    addToSchema,
    updateSchema,
    selectedKeys,
    deleteFromSchema,
    checkIfDemiRameIsAloneForLinkOut,
    isLoading,
    mode,
    deleteSchema,
    setModalVisible,
    getElementData,
    transformersElement,
    linkSwitchIn,
    setLinkSwitchIn,
    linkSwitchBetween,
    setLinkSwitchBetween,
    setLinkSwitchInPreviousValue,
    inputName,
    setInputName,
    setCellNumber,
    cellNumber,
    color,
    setColor,
    setLinkSwitchInId,
    setLinkSwitchBetweenId,
    setLinkSwitchInPreviousValueId,
    postAT,
    id,
    setIsLoadingButton,
    isLoadingButton,
    getSourcePost,
    origin
  } = useSchemaContext();
  const [warningText, setWarningText] = useState(false);
  const [warningColor, setWarningColor] = useState(false);
  const [warningAT, setwarningAT] = useState(false);
  const [warningAtData, setwarningAtData] = useState(false);

  const [ATData, setATData] = useState({ installedPower: 0, sourcePost: id });

  const updateTransformer = () =>
    updateTransformerTranslator({
      setWarningText,
      setWarningColor,
      setwarningAT,
      setwarningAtData,
      editType,
      ATData,
      postAT,
      setPurpose,
      purpose,
      addToSchema,
      inputName,
      cellNumber,
      color,
      linkSwitchIn,
      linkSwitchBetween,
      updateSchema,
      selectedKeysInfo,
      setIsLoadingButton,
      isLoadingButton,
      getSourcePost,
      origin
    });

  useEffect(() => {
    setATData({ ...ATData, color });
  }, [color]);

  const switchPurpose = (type) => {
    if (
      type === 'RA' &&
      (!transformersElement.length ||
        selectedKeysInfo.selectedNodes[0]?.children?.length === 2)
    ) {
      if (!transformersElement.length) message.error(t('schema.message.error'));
      if (selectedKeysInfo.selectedNodes[0]?.children?.length === 2)
        message.error(t('schema.message.full_ra'));
    } else {
      setPurpose('create');
    }
  };

  return (
    <div className="schema-toolbar-wrapper">
      <Row style={{ width: '100%' }}>
        <Col span={12}>
          {stepButtons.map((button) => (
            <Button
              key={button.key}
              type={button.key === currentStep ? 'primary' : 'default'}
              disabled={
                button.title === 'AT' && mode !== 'directSave'
                  ? true
                  : disabledButton[
                      selectedKeysInfo?.node?.type || 'NO'
                    ]?.includes(button.title)
              }
              onClick={() => {
                setCurrentStep(button.key);
                setPurpose(`init`);
                setEditType(button.title);
              }}
            >
              {button.icon} {button.title}
            </Button>
          ))}
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Popconfirm
            title={t('datatable.column.action.delete.title_schema')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteSchema('SP')}
            icon={<WarningOutlined />}
          >
            <Button danger>
              <PlusSquareOutlined /> {t('buttons.delete')}
            </Button>
          </Popconfirm>
          <Button
            type="default"
            disabled={mode === 'directSave'}
            onClick={() => checkIfDemiRameIsAloneForLinkOut('post')}
            loading={isLoading}
          >
            <SaveOutlined />
            {t(`buttons.${mode === 'directSave' ? 'auto_save' : 'save'}`)}
          </Button>
        </Col>
      </Row>
      <Row style={{ width: '100%', padding: '8px 8px 16px' }} align="middle">
        {warningText && (
          <div style={{ color: 'red', width: '100%', fontStyle: 'italic' }}>
            {t('schema.warning.text')}
          </div>
        )}
        {warningColor && (
          <div
            style={{
              color: 'red',
              width: '100%',
              marginBottom: '10px',
              fontStyle: 'italic'
            }}
          >
            {t('schema.warning.color')}
          </div>
        )}
        {warningAT && (
          <div
            style={{
              color: 'red',
              width: '100%',
              marginBottom: '10px',
              fontStyle: 'italic'
            }}
          >
            {t('schema.warning.AT')}
          </div>
        )}
        {warningAtData && (
          <div
            style={{
              color: 'red',
              width: '100%',
              marginBottom: '10px',
              fontStyle: 'italic'
            }}
          >
            {t('schema.warning.ATData')}
          </div>
        )}
        <Row style={{ width: '100%' }} align="middle">
          {purpose === 'create' || purpose === 'edit' ? (
            <ToolBarFormat
              blocks={[
                ...GeneralContent({
                  inputName,
                  setATData,
                  ATData,
                  setInputName,
                  color,
                  setColor,
                  editType,
                  setCellNumber,
                  cellNumber,
                  purpose,
                  t,
                  selectedKeysInfo
                }),
                ...(editType === 'AT'
                  ? ATContent({ setATData, ATData, inputName, schema, t })
                  : []),
                ...MixedContent({
                  editType,
                  transformersElement,
                  setLinkSwitchInId,
                  setLinkSwitchIn,
                  linkSwitchIn,
                  purpose,
                  selectedKeysInfo,
                  schema,
                  setATData,
                  ATData,
                  setLinkSwitchBetweenId,
                  setLinkSwitchBetween,
                  linkSwitchBetween,
                  t
                }),
                ...ButtonsContent({
                  checkWarningUpdate: () =>
                    checkWarningUpdate(
                      purpose,
                      schema,
                      transformersElement,
                      inputName,
                      color,
                      setWarningText,
                      setWarningColor,
                      AutoTRArray,
                      ATData,
                      setwarningAT,
                      setwarningAtData,
                      editType
                    ) && updateTransformer(),
                  setWarningColor,
                  setWarningText,
                  setwarningAT,
                  setwarningAtData,
                  setPurpose,
                  t,
                  isLoadingButton
                })
              ]}
            />
          ) : (
            <Button type="link" onClick={() => switchPurpose(editType)}>
              {`${t(`schema.init.${editType}`)} ${
                editType !== 'TR' && editType !== 'CO' && editType !== 'AT'
                  ? `sur ${t(`schema.selected.${editType}`)} "${
                      selectedKeysInfo?.node?.title
                    }"`
                  : ''
              }`}
            </Button>
          )}
        </Row>

        {selectedKeys?.length ? (
          <Row>
            <span
              style={{
                color: selectedKeysInfo?.node?.color,
                padding: 10
              }}
            >
              {`${selectedKeysInfo?.node?.title} :`}
              {editType !== 'CO' ? (
                <Button
                  type="link"
                  onClick={() => {
                    setPurpose('edit');
                    setInputName(selectedKeysInfo?.node?.title);
                    setColor(selectedKeysInfo?.node?.color);
                    setLinkSwitchIn(selectedKeysInfo?.node?.link_switch_in);
                    setLinkSwitchInPreviousValue(
                      selectedKeysInfo?.node?.link_switch_in
                    );
                    setLinkSwitchInPreviousValueId(
                      selectedKeysInfo?.node?.link_switch_in_id
                    );
                    setLinkSwitchBetween(
                      selectedKeysInfo?.node?.link_switch_between
                    );
                  }}
                >
                  <EditOutlined style={{ fontSize: 18 }} />
                </Button>
              ) : (
                []
              )}

              {mode === 'directSave' ? (
                <Button
                  type="link"
                  onClick={async () => {
                    await getElementData(
                      modelName[selectedKeysInfo?.node?.type],
                      selectedKeysInfo?.node?.id
                    );
                    setModalVisible(true);
                  }}
                >
                  <EyeOutlined style={{ fontSize: 18 }} />
                </Button>
              ) : (
                []
              )}

              {mode !== 'directSave' && editType !== 'TR' ? (
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ danger: true }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteFromSchema(selectedKeys)}
                  icon={<WarningOutlined />}
                >
                  <Button type="link">
                    <DeleteOutlined style={{ fontSize: 18, color: 'red' }} />
                  </Button>
                </Popconfirm>
              ) : (
                []
              )}
            </span>
          </Row>
        ) : (
          []
        )}
      </Row>
    </div>
  );
};

export default Toolbar;

Toolbar.propTypes = {
  AutoTRArray: PropTypes.arrayOf(PropTypes.shape({}))
};

Toolbar.defaultProps = {
  AutoTRArray: []
};
