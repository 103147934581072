import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useFormatter } from '../../../utils/formatter';

const WeatherData = ({ id, origin }) => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();

  const headers = [
    {
      label: 'sourcePost',
      key: 'name'
    },
    {
      label: 'PowerD12toPmax',
      key: 'PowerD12toPmax'
    },
    {
      label: 'NominalTempTN',
      key: 'NominalTempTN'
    },
    {
      label: 'MinimalTempTMB',
      key: 'MinimalTempTMB'
    },
    {
      label: 'DayTempTJ',
      key: 'DayTempTJ'
    },
    {
      label: 'TCMA',
      key: 'TCMA'
    },
    {
      label: 'gradientG',
      key: 'gradientG'
    },
    {
      label: 'cosPhi',
      key: 'cosPhi'
    },
    {
      label: 'yearMES',
      key: 'yearMES'
    },
    {
      label: 'sourcePost',
      key: 'sourcePost._id'
    }
  ];

  const columns = [
    {
      title: t('sourceposts.show.weatherData.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: ({ name }) => name,
      sorter: true
    },
    {
      title: t('sourceposts.show.weatherData.PowerD12toPmax'),
      key: 'PowerD12toPmax',
      dataIndex: 'PowerD12toPmax',
      sorter: true
    },
    {
      title: t('sourceposts.show.weatherData.NominalTempTN'),
      key: 'NominalTempTN',
      dataIndex: 'NominalTempTN',
      sorter: true
    },
    {
      title: t('sourceposts.show.weatherData.MinimalTempTMB'),
      key: 'MinimalTempTMB',
      dataIndex: 'MinimalTempTMB',
      sorter: true
    },
    {
      title: t('sourceposts.show.weatherData.DayTempTJ'),
      key: 'DayTempTJ',
      dataIndex: 'DayTempTJ',
      sorter: true
    },
    {
      title: t('sourceposts.show.weatherData.TCMA'),
      key: 'TCMA',
      dataIndex: 'TCMA',
      sorter: true
    },
    {
      title: t('sourceposts.show.weatherData.gradientG'),
      key: 'gradientG',
      dataIndex: 'gradientG',
      sorter: true
    },
    {
      title: t('sourceposts.show.weatherData.cosPhi'),
      key: 'cosPhi',
      dataIndex: 'cosPhi',
      sorter: true
    },
    {
      title: t('weatherdata.form.yearMES'),
      key: 'yearMES',
      render: (yearMES) => (yearMES && moment(yearMES).format('YYYY')) || '-',
      dataIndex: 'yearMES',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="weatherdata"
      populate="sourcePost"
      extraQuery={`sourcePost=${id}&model=${origin}`}
      columns={columns}
      headers={headers}
      resourceModelName="weatherdata"
      showTitle={false}
      createUrl={`/${origin}/weather`}
      path={`/${origin}/weather`}
      showAction={false}
      formatter={formattedData}
    />
  );
};

export default WeatherData;

WeatherData.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired
};
