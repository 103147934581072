import React from 'react';
import PropTypes from 'prop-types';

const OpenedClosedSwitch = ({
  opened,
  name,
  onClick = () => {},
  canEdit,
  fct = () => {}
}) => (
  <div ref={(el) => fct(el)}>
    <p style={{ display: 'inline-flex', margin: '1em' }}>
      <button
        style={{ all: 'unset' }}
        onClick={() => {
          if (!canEdit) return;
          onClick();
        }}
        type="button"
      >
        <span
          style={{
            backgroundColor: opened ? '#36B395' : '#FF4128',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {opened ? 'F' : 'O'}
        </span>
      </button>
      <span style={{ marginLeft: '0.5em' }}>{name}</span>
    </p>
  </div>
);

export default OpenedClosedSwitch;

OpenedClosedSwitch.propTypes = {
  opened: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  canEdit: PropTypes.bool.isRequired,
  fct: PropTypes.func
};

OpenedClosedSwitch.defaultProps = {
  onClick: () => {},
  fct: () => {},
  opened: null
};
