import React from 'react';
import { Typography, Select } from 'antd';

const { Option } = Select;

export const MixedContent = ({
  editType,
  transformersElement,
  setLinkSwitchInId,
  setLinkSwitchIn,
  linkSwitchIn,
  purpose,
  selectedKeysInfo,
  schema,
  setATData,
  ATData,
  setLinkSwitchBetweenId,
  setLinkSwitchBetween,
  linkSwitchBetween,
  t
}) => [
  {
    key: 7,
    content:
      (editType === 'RA' && transformersElement.length && purpose !== 'edit') ||
      (editType === 'DE' &&
        purpose === 'edit' &&
        selectedKeysInfo.node.type !== 'DE' &&
        transformersElement.length) ? (
        <>
          <Typography>{t('schema.toolbar.link_transformer')}:</Typography>
          <Select
            style={{ width: 150, marginRight: 7 }}
            onSelect={(data) => {
              setLinkSwitchInId(transformersElement[data].id);
              setLinkSwitchIn(data);
            }}
            defaultValue={linkSwitchIn}
          >
            {(transformersElement || []).map((transformer) => (
              <Option key={transformer.key} value={transformer.key}>
                {transformer.title}
              </Option>
            ))}
          </Select>
        </>
      ) : (
        []
      )
  },
  {
    key: 8,
    content:
      (editType === 'RA' &&
        transformersElement.length &&
        selectedKeysInfo.node?.children?.length &&
        purpose !== 'edit') ||
      editType === 'AT' ||
      (editType === 'DE' &&
        purpose === 'edit' &&
        selectedKeysInfo.node.type !== 'DE' &&
        transformersElement.length &&
        schema.transformers[
          parseFloat(selectedKeysInfo.node.key?.toString().charAt(2))
        ].children.length > 1) ? (
        <>
          <Typography>{t('schema.toolbar.link_dr')}:</Typography>
          <Select
            style={{ width: 150 }}
            onSelect={(data) => {
              if (editType === 'AT') {
                setATData({
                  ...ATData,
                  demirame: data
                });
              } else {
                setLinkSwitchBetweenId(
                  schema.transformers[parseFloat(data?.toString().charAt(2))]
                    ?.children[parseFloat(data?.toString().slice(4))]?.id
                );
                setLinkSwitchBetween(data);
              }
            }}
            defaultValue={linkSwitchBetween}
          >
            {(editType === 'AT'
              ? schema?.transformers
                  ?.map((tr) => tr.id === ATData?.collumn && tr.children)
                  .flat()
              : (
                  (purpose === 'edit'
                    ? schema.transformers[
                        parseFloat(
                          selectedKeysInfo.node.key?.toString().charAt(2)
                        )
                      ]?.children
                    : selectedKeysInfo?.node?.children) || []
                ).filter(
                  (element) => element.title !== selectedKeysInfo.node.title
                )
            )?.map((parent) => (
              <Option
                key={parent.key}
                value={editType === 'AT' ? parent.id : parent.key}
              >
                {parent.title}
              </Option>
            ))}
          </Select>
        </>
      ) : (
        []
      )
  }
];
