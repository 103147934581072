export const elementList = (data, t, type) => {
  const {
    title,
    nominalPower,
    voltageHTB,
    voltageHTA,
    voltageSet,
    serviceYear,
    arrival1Name,
    arrival2Name,
    voltage,
    rameTechnology,
    cellsNumber,
    departCodeGDO,
    libelle,
    libelleERABLESIG,
    libellePHILEAS,
    acc,
    tempSensible
  } = data;
  return [
    {
      label: `${t(`${type}.form.name`)}`,
      content: title ? `${title}` : '-',
      span: 3
    },
    ...(type === 'transformer'
      ? [
          {
            label: `${t(`transformer.form.nominalPower`)}`,
            content: nominalPower ? `${nominalPower}` : '-',
            span: 3
          },
          {
            label: `${t(`transformer.form.voltageHTB`)}`,
            content: voltageHTB ? `${voltageHTB}` : '-',
            span: 3
          },
          {
            label: `${t(`transformer.form.voltageHTA`)}`,
            content: voltageHTA ? `${voltageHTA}` : '-',
            span: 3
          },
          {
            label: `${t(`transformer.form.voltageSet`)}`,
            content: voltageSet ? `${voltageSet}` : '-',
            span: 3
          },
          {
            label: `${t(`transformer.form.serviceYear`)}`,
            content: serviceYear ? `${serviceYear}` : '-',
            span: 3
          }
        ]
      : []),
    ...(type === 'demirame'
      ? [
          {
            label: `${t(`demirame.form.arrival1Name`)}`,
            content: arrival1Name ? `${arrival1Name}` : '-',
            span: 3
          },
          {
            label: `${t(`demirame.form.arrival2Name`)}`,
            content: arrival2Name ? `${arrival2Name}` : '-',
            span: 3
          },
          {
            label: `${t(`demirame.form.voltage`)}`,
            content: voltage ? `${voltage}` : '-',
            span: 3
          },
          {
            label: `${t(`demirame.form.rameTechnology`)}`,
            content: rameTechnology ? `${rameTechnology}` : '-',
            span: 3
          },
          {
            label: `${t(`demirame.form.cellsNumber`)}`,
            content: cellsNumber ? `${cellsNumber}` : '-',
            span: 3
          }
        ]
      : []),
    ...(type === 'depart'
      ? [
          {
            label: `${t(`depart.form.departCodeGDO`)}`,
            content: departCodeGDO ? `${departCodeGDO}` : '-',
            span: 3
          },
          {
            label: `${t(`depart.form.libelle`)}`,
            content: libelle ? `${libelle}` : '-',
            span: 3
          },
          {
            label: `${t(`depart.form.libelleERABLESIG`)}`,
            content: libelleERABLESIG ? `${libelleERABLESIG}` : '-',
            span: 3
          },
          {
            label: `${t(`depart.form.libellePHILEAS`)}`,
            content: libellePHILEAS ? `${libellePHILEAS}` : '-',
            span: 3
          },
          {
            label: `${t(`depart.form.voltage`)}`,
            content: voltage ? `${voltage}` : '-',
            span: 3
          },
          {
            label: `${t(`depart.form.acc`)}`,
            content: acc ? `${acc}` : '-',
            span: 3
          },
          {
            label: `${t(`depart.form.tempSensible`)}`,
            content: `${t(`depart.form.switch.${tempSensible}`)}`,
            span: 3
          }
        ]
      : [])
  ];
};
