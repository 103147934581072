import React from 'react';
import { Modal, Select, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSchemaContext } from './SchemaContext';

const { Option } = Select;

const LinkOutModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    openLinkOutModal,
    setOpenLinkOutModal,
    aloneDemiRame,
    schema,
    transformersElement,
    setSchema,
    postSchema,
    mode,
    patchDemiRameLinkOut
  } = useSchemaContext();

  const onValidate = async () => {
    await form.validateFields();
    if (mode === 'directSave') {
      await patchDemiRameLinkOut();
    } else {
      await postSchema();
    }
    setOpenLinkOutModal(false);
  };

  return (
    <Modal
      title={t('schema.modal_link.title')}
      centered
      visible={openLinkOutModal}
      width={500}
      footer={false}
      onCancel={() => {
        setOpenLinkOutModal(false);
      }}
    >
      <Form form={form} onFinish={onValidate}>
        {aloneDemiRame.map((demiRame, index) => (
          <Form.Item
            key={demiRame.title}
            hasFeedback={form.validateFields()}
            label={demiRame.title}
            name={`link_out_${index}`}
            rules={[
              {
                required: true,
                message: t('errors.form.REQUIRED')
              }
            ]}
          >
            <Select
              style={{ width: 150, marginRight: 7 }}
              onSelect={(data) => {
                const newSchema = { ...schema };
                newSchema.transformers[
                  demiRame.indexCO
                ].children[0].link_switch_out = data;
                newSchema.transformers[
                  demiRame.indexCO
                ].children[0].link_switch_out_id =
                  transformersElement[parseInt(data, 10)]?.id;
                setSchema({ ...newSchema });
              }}
            >
              {(transformersElement || []).map((transformer) => (
                <Option key={transformer.key} value={transformer.key}>
                  {transformer.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ))}
        <Form.Item
          style={{
            marginTop: 35
          }}
        >
          <Button type="primary" htmlType="submit">
            {t('buttons.save')}
          </Button>
          <Button type="link" danger onClick={() => setOpenLinkOutModal(false)}>
            {t('buttons.cancel')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LinkOutModal;
