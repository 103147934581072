/* eslint-disable no-param-reassign */
export const onDragEnd = async (
  index,
  isDraggedDE,
  schema,
  isDragged,
  patchDeparts,
  setSchema,
  patchSchema,
  patchDemiRame,
  setIsDragged,
  typeOfPatch
) => {
  if (isDraggedDE) return;
  const newSchema = schema;
  // There can only be a maximum of 2 demirames in a column
  if (newSchema.transformers[index].children.length >= 2) return;

  // remove the demirame from the old column and fix the keys
  for (let i = 0; i < newSchema.transformers.length; i += 1) {
    for (let j = 0; j < newSchema.transformers[i].children.length; j += 1) {
      if (newSchema.transformers[i].children[j].title === isDragged.title) {
        newSchema.transformers[i].children.splice(j, 1);
        // fix keys and link between for every demirame left in the column
        for (let k = 0; k < newSchema.transformers[i].children.length; k += 1) {
          newSchema.transformers[i].children[k].key = `0-${i}-${k}`;
          newSchema.transformers[i].children[k].link_switch_between_id = '';
          newSchema.transformers[i].children[k].link_switch_between = '';
          newSchema.transformers[i].children[k].electric_switch_in = true;
          newSchema.transformers[i].children[k].electric_switch_between = false;
          newSchema.transformers[i].children[k].electric_switch_out = true;
          // fix keys for every depart in the demirame still in the column
          for (
            let l = 0;
            l < newSchema.transformers[i].children[k].children.length;
            l += 1
          ) {
            newSchema.transformers[i].children[k].children[
              l
            ].key = `0-${i}-${k}-${l}`;
            // reset switches to on-off-on
          }
        }
      }
    }
  }

  // key fixing for the demirame that is being dragged
  const childLen = newSchema.transformers[index].children.length;
  const key = `0-${index}-${childLen}`;
  isDragged.key = key;
  isDragged.colonne = newSchema.transformers[index].id;
  for (let i = 0; i < isDragged.children.length; i += 1) {
    isDragged.children[i].key = `${key}-${i}`;
    isDragged.children[i].colonne = newSchema.transformers[index].id;
    patchDeparts(isDragged.children[i]);
  }

  // update links in demiRames
  if (childLen === 1) {
    newSchema.transformers[index].children[0].link_switch_between_id =
      isDragged.id;
    newSchema.transformers[index].children[0].link_switch_between = key;
    isDragged.link_switch_between_id =
      newSchema.transformers[index].children[0].id;
    isDragged.link_switch_between = `0-${index}-0`;
    // reset switches to on-off-on
    newSchema.transformers[index].children[0].electric_switch_in = true;
    newSchema.transformers[index].children[0].electric_switch_between = false;
    newSchema.transformers[index].children[0].electric_switch_out = true;
    isDragged.electric_switch_in = true;
    isDragged.electric_switch_between = false;
    isDragged.electric_switch_out = true;
  }
  // update the schema
  newSchema.transformers[index].children.push(isDragged);
  await setSchema(newSchema);
  patchSchema(typeOfPatch);
  await patchDemiRame(isDragged, newSchema.transformers[index].title);
  setIsDragged(null);
};

export const onDragEndDE = async (
  index1,
  index2,
  schema,
  isDraggedDE,
  setSchema,
  patchSchema,
  patchDeparts,
  setIsDraggedDE,
  typeOfPatch,
  isDragged
) => {
  const newSchema = schema;
  if (
    newSchema.transformers[index1].children[index2].children.filter(
      (DE) => DE.id === isDraggedDE.id || DE.title === isDraggedDE.title
    ).length > 0
  )
    return;

  // Remove the depart from the old demirame and fix the keys
  for (let i = 0; i < newSchema.transformers.length; i += 1) {
    for (let j = 0; j < newSchema.transformers[i].children.length; j += 1) {
      for (
        let k = 0;
        k < newSchema.transformers[i].children[j].children.length;
        k += 1
      ) {
        if (
          newSchema.transformers[i].children[j].children[k].title ===
          isDraggedDE.title
        ) {
          newSchema.transformers[i].children[j].children.splice(k, 1);
          // fix keys for every depart in the demirame still in the column
          for (
            let l = 0;
            l < newSchema.transformers[i].children[j].children.length;
            l += 1
          ) {
            newSchema.transformers[i].children[j].children[
              l
            ].key = `0-${i}-${j}-${l}`;
          }
        }
      }
    }
  }

  // key fixing for the depart that is being dragged
  const childLen =
    newSchema.transformers[index1].children[index2].children.length;
  const key = `0-${index1}-${index2}-${childLen}`;
  isDraggedDE.key = key;
  newSchema.transformers[index1].children[index2].children.push(isDraggedDE);
  isDraggedDE.colonne = newSchema.transformers[index1].id;
  isDraggedDE.demiRame = newSchema.transformers[index1].children[index2].id;
  // update the schema
  await setSchema(newSchema);
  patchSchema(typeOfPatch);
  await patchDeparts(
    isDraggedDE,
    isDragged.title,
    newSchema.transformers[index1].children[index2].title
  );
  setIsDraggedDE(null);
};
