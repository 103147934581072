import React from 'react';
import { Pie } from '@ant-design/plots';
import PropTypes from 'prop-types';

const DonutChart = ({ dataAdmin }) => {
  const donuts = () => {
    const data = dataAdmin;
    const config = {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 0.75,
      label: {
        type: 'spider',
        labelHeight: 28,
        content: '{name}\n{percentage}'
      },
      interactions: [
        {
          type: 'element-selected'
        },
        {
          type: 'element-active'
        }
      ],
      theme: {
        colors10: [
          '#47d267',
          '#bfbfbf',
          '#1423DC',
          '#9FB40F',
          '#76523B',
          '#DAD5B5',
          '#0E8E89',
          '#E19348',
          '#F383A2',
          '#247FEA'
        ]
      }
    };
    return <Pie {...config} />;
  };

  return donuts();
};

DonutChart.propTypes = {
  dataAdmin: PropTypes.instanceOf(Array)
};

DonutChart.defaultProps = {
  dataAdmin: undefined
};

export default DonutChart;
