const calculatePosition = (
  theseusPosition,
  myTransformer,
  result,
  firstLine,
  ra
) => {
  const theseusPositionCopy = theseusPosition;
  theseusPositionCopy.y += firstLine;
  result.push({
    x: theseusPosition.x,
    y: theseusPosition.y,
    height: firstLine,
    color: ra.link_in_closed ? myTransformer?.color : '#8c8686'
  });

  const secondLine =
    myTransformer && myTransformer.location.x - theseusPosition.x;
  theseusPositionCopy.x += secondLine;
  result.push({
    x: secondLine > 0 ? theseusPosition.x - 4 : theseusPosition.x,
    y: theseusPosition.y,
    width: secondLine,
    color: ra.link_in_closed ? myTransformer?.color : '#8c8686'
  });

  const thirdLine =
    myTransformer && myTransformer.location.bottom - theseusPosition.y;
  theseusPositionCopy.y += thirdLine;
  result.push({
    x: theseusPosition.x,
    y: theseusPosition.y,
    height: thirdLine,
    color: myTransformer?.color
  });
};

export const theseus = (locationTransfo) => {
  const demiRames = Object.keys(locationTransfo)
    .filter((f) => locationTransfo[f].type === 'demi-rame')
    .map((f) => locationTransfo[f]);

  let maxBottom = 0;
  demiRames.forEach((ra) => {
    if (ra.location.bottom > maxBottom) {
      maxBottom = ra.location.bottom;
    }
  });

  const result = [];

  let gap = 0;

  demiRames.forEach((ra) => {
    const myTransformer = locationTransfo[ra.isLinkedTo];
    if (ra.lineDirection === 0 || ra.lineDirection === 2) {
      const theseusPosition = {
        x: ra.location.x - 10,
        y: ra.location.y + 13
      };
      calculatePosition(theseusPosition, myTransformer, result, -30 - gap, ra);
    }
    if (ra.lineDirection === 1 || ra.lineDirection === 2) {
      const theseusPosition = {
        x: ra.location.x - 10,
        y: ra.location.bottom - 13
      };
      const firstLine = maxBottom - theseusPosition.y + 20 + gap;
      if (ra.lineDirection === 2 && ra.link_out) {
        calculatePosition(
          theseusPosition,
          locationTransfo[ra.link_out],
          result,
          firstLine,
          ra
        );
      } else {
        calculatePosition(
          theseusPosition,
          myTransformer,
          result,
          firstLine,
          ra
        );
      }
    }
    gap += 10;
  });
  return result;
};
