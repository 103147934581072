import React from 'react';
import { Input, Typography, Select } from 'antd';

const { Option } = Select;

const ATPositions = [
  { key: 1, title: 'Entrée' },
  { key: 2, title: 'Sortie' }
];

export const ATContent = ({ setATData, ATData, inputName, schema, t }) => [
  {
    key: 2,
    content: (
      <>
        <Typography>{t('schema.toolbar.installed_power')}:</Typography>
        <Input
          allowClear
          style={{
            width: 100
          }}
          defaultValue={inputName}
          onChange={(e) => {
            setATData({
              ...ATData,
              installedPower: e.target.value
            });
          }}
        />
      </>
    )
  },
  {
    key: 4,
    content: (
      <>
        <Typography>{t('schema.toolbar.column')}:</Typography>
        <Select
          style={{ width: 150, marginRight: 7 }}
          onSelect={(data) => {
            setATData({ ...ATData, collumn: data, demirame: null });
          }}
        >
          {schema?.transformers?.map((parent) => (
            <Option key={parent.key} value={parent.id}>
              {parent.title}
            </Option>
          ))}
        </Select>
      </>
    )
  },
  {
    key: 5,
    content: (
      <>
        <Typography>{t('schema.toolbar.position')}:</Typography>
        <Select
          style={{ width: 150 }}
          onSelect={(data) => {
            setATData({ ...ATData, position: data });
          }}
        >
          {ATPositions.map((parent) => (
            <Option key={parent.key} value={parent.key}>
              {parent.title}
            </Option>
          ))}
        </Select>
      </>
    )
  }
];
