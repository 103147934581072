import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateResearch = ({ purpose }) => {
  const { message } = useErrorMessage();
  const [users, setUsers] = useState([]);
  const [sourcePosts, setSourcePosts] = useState([]);
  const { fields, isFieldsLoading } = useFields(users, sourcePosts, purpose);
  const { user, dispatchAPI } = useAuthContext();
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSourcePosts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/sourceposts'
      });
      setSourcePosts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getUsers();
      await getSourcePosts();
    })();
  }, []);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...(!isSuperAdmin ? { contact: user._id } : {})
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="research"
      resource="research"
      config={config}
    />
  );
};

CreateUpdateResearch.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateResearch;
