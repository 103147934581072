import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Input, Menu, Row } from 'antd';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { useAuthContext } from '../../contexts/AuthContext';

const { Search } = Input;

export const ListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  withSearchButton,
  forceRefresh,
  resourceModelName,
  editAction,
  showAction,
  canEditOrDeleteFct,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  showTitle,
  createUrl,
  formattedData,
  formatter,
  customPagination,
  withExportButton,
  withImportButton
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuthContext();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const isAdmin =
    user.role === 'admins:SUPER-ADMIN' || user.role === 'admins:ADMIN';

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = (
    <Menu>
      {headers && (
        <Menu.Item key="export">
          {withExportButton && (
            <ExportButton
              dataName={resourceName}
              headers={headers}
              url={`/${exportUrl || resourceName}`}
              fileName={`${resourceName}.csv`}
              populate={populate}
              extraQuery={extraQuery}
              formatter={formatter}
            />
          )}
        </Menu.Item>
      )}
      <Menu.Item key="import">
        {withImportButton && (
          <ImportButton resourceName={resourceModelName} headers={headers} />
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {showTitle && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
        />
      )}
      <ContentCustom>
        <Row justify="space-between" gutter={[8, 16]}>
          <Col>
            {withSearchButton && (
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onSearch={(value) => searchResource(value)}
              />
            )}
          </Col>
          <Col>
            <Row align="middle">
              {extraButtons}
              {withCreateButton && isAdmin && (
                <Link
                  to={`${createUrl || pathname}/create${
                    createUrl ? `?postesSources=${id}` : ''
                  }`}
                >
                  <Button type="add">
                    {`${t('buttons.create')}`}
                    &nbsp;
                    <PlusOutlined />
                  </Button>
                </Link>
              )}

              {withUploadButton && (
                <Dropdown overlay={menu}>
                  <Button type="link">
                    <MenuOutlined
                      style={{ fontSize: 16, color: 'var(--textColor)' }}
                    />
                  </Button>
                </Dropdown>
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          canEditOrDeleteFct={canEditOrDeleteFct}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1800 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          isAdmin={isAdmin}
          formattedData={formattedData}
          customPagination={customPagination}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.bool,
  withUploadButton: PropTypes.bool,
  withSearchButton: PropTypes.bool,
  withExportButton: PropTypes.bool,
  withImportButton: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  canEditOrDeleteFct: PropTypes.func,
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  showTitle: PropTypes.bool,
  createUrl: PropTypes.string,
  formattedData: PropTypes.string,
  formatter: PropTypes.func,
  customPagination: PropTypes.number
};

ListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withSearchButton: true,
  withExportButton: true,
  withImportButton: true,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  deleteAction: true,
  canEditOrDeleteFct: () => true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  showTitle: true,
  createUrl: undefined,
  formattedData: null,
  formatter: null,
  customPagination: undefined
};
