/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Select, Row, notification, Tooltip, Popover } from 'antd';
import { CloseOutlined, SplitCellsOutlined } from '@ant-design/icons';
import { useSchemaContext } from './SchemaContext';
import { theseus } from '../utils/createPipe';
import Lines from '../utils/schemaLines';
import OpenedClosedSwitch from './OpenClosedSwtich';
import {
  formatNum,
  handleData,
  getDRData,
  getDRMaxData,
  canSwitch
} from './utils';

const { Option } = Select;

const styles = {
  tableHeader: {
    backgroundColor: '#F1F2F3',
    justifyContent: 'center',
    textAlign: 'center'
  },
  tableContent: { justifyContent: 'center', textAlign: 'center' }
};
let locationTransfo = {};

const SelectButton = ({ name, isSelected, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    style={{
      all: 'unset',
      backgroundColor: `${isSelected ? '#1423DC' : 'white'}`,
      color: `${isSelected ? 'white' : 'black'}`,
      border: `1px solid ${isSelected ? '#1423DC' : '#C8CBD0'}`,
      width: '5em',
      textAlign: 'center',
      borderRadius: 2
    }}
  >
    {name}
  </button>
);

const TransformersMapping = ({
  width,
  mode,
  totals,
  canDisableTR,
  projection
}) => {
  const { transformersElement } = useSchemaContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const transfoLocationInfo = (el, item) => {
    if (!el) return;
    locationTransfo = {
      ...locationTransfo,
      [item.title]: {
        title: item.title,
        location: el.getBoundingClientRect(),
        color: item.color
      }
    };
  };

  return transformersElement?.map((item) => (
    <Tooltip
      key={item.id}
      placement="bottom"
      title={
        <div>
          <p style={{ marginBottom: 5 }}>
            {`Tension de consigne: ${
              totals
                ?.find((el) => el._id === item.id)
                ?.voltageSet?.toFixed(2) || 0
            } kV`}
          </p>
          <p style={{ marginBottom: 5 }}>{`Année de MES: ${moment(
            totals?.find((el) => el._id === item.id)?.serviceYear
          ).format('YYYY')}`}</p>
        </div>
      }
    >
      <div
        key={item.id}
        style={{ width }}
        ref={(el) => transfoLocationInfo(el, item)}
        className="transformer-wrapper"
      >
        <h2
          className="transformer-title"
          style={{ background: isDisabled === item.id ? 'grey' : item.color }}
        >
          {item.title}
          {canDisableTR && (
            <button
              type="button"
              style={{
                background: 'rgba(0, 0, 0, 0)',
                border: 'none'
              }}
              onClick={() => setIsDisabled(item.id)}
            >
              <CloseOutlined
                style={{ background: 'rgba(0, 0, 0, 0)', color: 'red' }}
              />
            </button>
          )}
        </h2>
        <table className="schema-table">
          <tbody>
            <tr>
              <td rowSpan="4">S Courant</td>
              <th>
                <Row justify="space-between">
                  <span
                    style={{
                      color: 'var(--disabledColor)',
                      paddingLeft: '5px'
                    }}
                  >
                    Stmb
                  </span>
                  <span style={{ paddingRight: '5px' }}>
                    {handleData(
                      item.id,
                      'puissance-total',
                      mode,
                      totals,
                      projection
                    )}
                  </span>
                </Row>
              </th>
            </tr>
            <tr>
              <th>
                <Row justify="space-between">
                  <span
                    style={{
                      color: 'var(--disabledColor)',
                      paddingLeft: '5px'
                    }}
                  >
                    Itmb
                  </span>
                  <span style={{ paddingRight: '5px' }}>
                    {handleData(
                      item.id,
                      'transit-total',
                      mode,
                      totals,
                      projection
                    )}
                  </span>
                </Row>
              </th>
            </tr>
            <tr>
              <th>
                <Row justify="space-between">
                  <span
                    style={{
                      color: 'var(--disabledColor)',
                      paddingLeft: '5px'
                    }}
                  >
                    Pn
                  </span>
                  <span style={{ paddingRight: '5px' }}>
                    {`${
                      totals
                        ?.find((el) => el._id === item.id)
                        ?.nominalPower?.toFixed(2) || 0
                    } MVA`}
                  </span>
                </Row>
              </th>
            </tr>
            <tr>
              <th>
                <Row justify="end">
                  {handleData(item.id, 'percent', mode, totals, projection)}
                </Row>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </Tooltip>
  ));
};

const DemieRameMapping = ({
  demierames,
  intNumber,
  mode,
  update,
  canEdit,
  tableData,
  ATData,
  offset,
  projection
}) => {
  const { transformersElement, selectedYear, getATforDR } = useSchemaContext();
  const [isLoading, setIsLoading] = useState(true);
  const [AutoTRArray, setAutoTRArray] = useState([]);

  useEffect(async () => {
    for (let i = 0; i < demierames.length; i++) {
      const data = await getATforDR(demierames[i].id);
      if (data) setAutoTRArray((prev) => [...prev, data]);
    }
    setIsLoading(false);
  }, [demierames]);

  const demiRameLocationInfo = (el, item, index) => {
    if (!el) return;
    locationTransfo = {
      ...locationTransfo,
      [item.title]: {
        title: item.title,
        location: el.getBoundingClientRect(),
        isLinkedTo: transformersElement.filter(
          (tr) => tr.link.filter((lnk) => lnk.title === item.title)?.length > 0
        )[0]?.title,
        link_out: transformersElement.filter(
          (tr) => tr.id === item?.link_switch_out_id
        )[0]?.title,
        type: 'demi-rame',
        // eslint-disable-next-line no-nested-ternary
        lineDirection: demierames.length === 1 ? 2 : index === 0 ? 0 : 1,
        link_in_closed: item.electric_switch_in,
        link_out_closed: item.electric_switch_out
      }
    };
  };

  const buttons = demierames?.map((item) => [
    item.electric_switch_in,
    item.electric_switch_out,
    item.electric_switch_between
  ]);

  return demierames?.map((item, index) => {
    const { transit, puissance, tension } = getDRData(
      tableData?.filter((data) => data.demiRame === item.id) || []
    );
    const { transitMax, puissanceMax, tensionMax } = getDRMaxData(
      tableData?.filter((data) => data.demiRame === item.id) || []
    );
    const buttonsLocation = [
      { top: 0, bottom: 0, left: 0 },
      { top: 0, bottom: 0, left: 0 }
    ];

    const color =
      transformersElement.filter(
        (tr) => tr.link.filter((lnk) => lnk.title === item.title)?.length > 0
      )[0]?.color || '';

    const [toMap, setToMap] = useState([]);
    const [toMap2, setToMap2] = useState([]);

    useEffect(() => {
      // The mapped variable represents the lines that will be drawn
      // between the in and out switches and
      // between the out and between switches
      const mapped = buttonsLocation.map((btn, buttonIndex) => (
        <Lines
          key={Math.random()}
          x={btn.left - offset.x - 10}
          y={btn.bottom - offset.y + 10}
          height={btn.bottom - btn.top + 22}
          color={buttonIndex !== 1 ? color : 'grey'}
        />
      ));
      // The mapped2 variable represents the lines that will be drawn between the between and in switches
      if (index === 1) {
        const mapped2 = (
          <Lines
            x={buttonsLocation[0].left - offset.x - 10}
            y={buttonsLocation[0].top - offset.y - 40}
            height={20}
            color="grey"
          />
        );
        setToMap2(mapped2);
      }
      setToMap([mapped]);
    }, [isLoading]);

    return (
      <div
        key={item.title}
        ref={(el) => demiRameLocationInfo(el, item, index)}
        className="demirame-wrapper"
      >
        <div className="at-wrapper-entry">
          {AutoTRArray[index]
            ?.filter(
              (AT) =>
                AT.demirame === demierames[index]?.id &&
                AT.position === (index === 1 ? '2' : '1')
            )
            ?.map((data) => (
              <Popover
                key={data.name}
                placement="right"
                content={
                  <table className="schema-table at-table">
                    <tbody>
                      <tr key={item.id}>
                        <td rowSpan="2" className="at-name">
                          <span
                            style={{
                              backgroundColor: data?.color
                            }}
                          >
                            <SplitCellsOutlined />
                            AT {data?.name}
                          </span>
                        </td>
                        <th style={styles.tableHeader}>
                          Puissance installée (MVA)
                        </th>
                        <th style={styles.tableHeader}>Charge</th>
                        <th style={styles.tableHeader}>
                          Tension au primaire (kV)
                        </th>
                        <th style={styles.tableHeader}>
                          Intensité au primaire (A)
                        </th>
                      </tr>
                      <tr key={item.id}>
                        <td style={styles.tableContent}>
                          {data?.installedPower}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 0 && buttons[index][0]) ||
                          (index === 1 &&
                            buttons[index - 1][2] &&
                            buttons[index][1])
                            ? `${
                                mode === 'Ptmb'
                                  ? (ATData &&
                                      ATData[`${data?._id}`]?.charge) ||
                                    0
                                  : (ATData &&
                                      ATData[`${data?._id}`]?.chargeMax) ||
                                    0
                              }%`
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 0 && buttons[index][0]) ||
                          (index === 1 &&
                            buttons[index - 1][2] &&
                            buttons[index][1])
                            ? (ATData && ATData[`${data?._id}`]?.Uentree) || 0
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 0 && buttons[index][0]) ||
                          (index === 1 &&
                            buttons[index - 1][2] &&
                            buttons[index][1])
                            ? mode === 'Ptmb'
                              ? (ATData && ATData[`${data?._id}`]?.Ientree) || 0
                              : (ATData &&
                                  ATData[`${data?._id}`]?.IentreeMax) ||
                                0
                            : 0}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              >
                <span className="at-popover-btn">
                  <SplitCellsOutlined />
                  AT
                </span>
              </Popover>
            ))}
        </div>
        <OpenedClosedSwitch
          opened={index === 1 ? buttons[index][1] : buttons[index][0]}
          name={`Int ${index * 2 + intNumber}`}
          onClick={() => canEdit && update(index, index === 1 ? 'out' : 'in')}
          canEdit={canEdit}
          key={item.id}
          fct={(el) => {
            if (!el) return;
            const data = buttonsLocation[0];
            data.top = el.getBoundingClientRect().bottom;
            data.left = el.getBoundingClientRect().left;
            buttonsLocation[0] = data;
          }}
        />
        <table className="schema-table">
          <tbody>
            <tr key={item.id}>
              <td
                style={{
                  color: `${
                    formatNum(tensionMax) === formatNum(0.0) ? 'grey' : color
                  }`
                }}
              >
                <span>1/2 Rame {item.title}</span>
              </td>
              <th style={styles.tableHeader}>Transit (A)</th>
              <th style={styles.tableHeader}>Puissance (MVA)</th>
              {!selectedYear?.length ? (
                <th style={styles.tableHeader}>Tension (kV)</th>
              ) : null}
            </tr>
            {item.children.map((child) => (
              <tr key={child.id}>
                <td className="depart-title">
                  <span
                    style={{
                      ...styles.tableContent,
                      backgroundColor: `${
                        handleData(child.id, 'tension', mode, tableData) ===
                        formatNum(0.0)
                          ? 'grey'
                          : color
                      }`,
                      color: 'white'
                    }}
                  >
                    {`${child?.cell_number || 'N/A'} ${child.title}`}
                  </span>
                </td>
                <td style={styles.tableContent}>
                  {handleData(child.id, 'transit', mode, tableData, projection)}
                </td>
                <td style={styles.tableContent}>
                  {handleData(
                    child.id,
                    'puissance',
                    mode,
                    tableData,
                    projection
                  )}
                </td>
                {!selectedYear?.length ? (
                  <td style={styles.tableContent}>
                    {handleData(
                      child.id,
                      'tension',
                      mode,
                      tableData,
                      projection
                    )}
                  </td>
                ) : null}
              </tr>
            ))}
            <tr key={item._id}>
              <td />
              <td style={styles.tableHeader}>
                {mode === 'Ptmb' ? formatNum(transit) : formatNum(transitMax)}
              </td>
              <td style={styles.tableHeader}>
                {mode === 'Ptmb'
                  ? formatNum(puissance)
                  : formatNum(puissanceMax)}
              </td>
              {!selectedYear?.length ? (
                <td style={styles.tableHeader}>
                  {mode === 'Ptmb' ? formatNum(tension) : formatNum(tensionMax)}
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>

        <OpenedClosedSwitch
          opened={index === 1 ? buttons[index][0] : buttons[index][1]}
          name={`Int ${index * 2 + intNumber + 1}`}
          onClick={() => canEdit && update(index, index === 1 ? 'in' : 'out')}
          canEdit={canEdit}
          fct={(el) => {
            if (!el) return;
            const data = buttonsLocation[0];
            data.bottom = el.getBoundingClientRect().top;
            buttonsLocation[0] = data;
            const data2 = buttonsLocation[1];
            data2.top = el.getBoundingClientRect().bottom;
            buttonsLocation[1] = data2;
          }}
        />
        <div className="at-wrapper-exit">
          {AutoTRArray[index]
            ?.filter(
              (AT) =>
                AT.demirame === demierames[index]?.id &&
                AT.position === (index === 1 ? '1' : '2')
            )
            ?.map((data) => (
              <Popover
                key={data.name}
                content={
                  <table className="schema-table at-table">
                    <tbody>
                      <tr key={item.id}>
                        <td rowSpan="2" className="at-name">
                          <span
                            style={{
                              backgroundColor: data?.color
                            }}
                          >
                            <SplitCellsOutlined />
                            AT {data?.name}
                          </span>
                        </td>
                        <th style={styles.tableHeader}>
                          Puissance installée (MVA)
                        </th>
                        <th style={styles.tableHeader}>Charge</th>
                        <th style={styles.tableHeader}>
                          Tension au primaire (kV)
                        </th>
                        <th style={styles.tableHeader}>
                          Intensité au primaire (A)
                        </th>
                      </tr>
                      <tr key={item.id}>
                        <td style={styles.tableContent}>
                          {data?.installedPower}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 1 && buttons[index][0]) ||
                          (index === 0 &&
                            buttons[index][2] &&
                            buttons[index][1])
                            ? `${
                                (ATData && ATData[`${data?._id}`]?.charge) || 0
                              }%`
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 1 && buttons[index][0]) ||
                          (index === 0 &&
                            buttons[index][2] &&
                            buttons[index][1])
                            ? (ATData && ATData[`${data?._id}`]?.Uentree) || 0
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 1 && buttons[index][0]) ||
                          (index === 0 &&
                            buttons[index][2] &&
                            buttons[index][1])
                            ? (ATData && ATData[`${data?._id}`]?.Ientree) || 0
                            : 0}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              >
                <span className="at-popover-btn">
                  <SplitCellsOutlined />
                  AT
                </span>
              </Popover>
            ))}
        </div>
        {demierames.length === 2 && index === 0 && (
          <OpenedClosedSwitch
            opened={buttons[index][2]}
            name="between"
            onClick={() => canEdit && update(index, 'between')}
            canEdit={canEdit}
            fct={(el) => {
              if (!el) return;
              const data = buttonsLocation[1];
              data.bottom = el.getBoundingClientRect().top;
              data.left = el.getBoundingClientRect().left;
              buttonsLocation[1] = data;
            }}
          />
        )}
        {toMap}
        {toMap2}
      </div>
    );
  });
};

const ColumnMapping = ({
  width,
  mode,
  update,
  canEdit,
  tableData,
  ATData,
  offset,
  projection
}) => {
  const { schema } = useSchemaContext();

  return (
    schema &&
    schema?.transformers?.map((column, index) => (
      <div key={column.id} style={{ width }} className="schema-column">
        <DemieRameMapping
          demierames={column?.children}
          intNumber={
            1 +
            schema.transformers
              .slice(0, index)
              .reduce((acc, transfo) => acc + transfo.children.length, 0) *
              2
          }
          mode={mode}
          canEdit={canEdit}
          update={(demirame, type) => update(index, demirame, type)}
          tableData={tableData}
          ATData={ATData}
          offset={offset}
          projection={projection}
        />
      </div>
    ))
  );
};

const TransformersSchema = ({
  canEdit,
  results,
  getResource,
  isSchemaLoading
}) => {
  const {
    transformersElement,
    schema,
    setSchema,
    patchSchema,
    patchDemiRame,
    refYear,
    setRefYear,
    selectedYear,
    setSelectedYear,
    yearsImport,
    yearsProject,
    origin,
    switchAlreadyUsed
  } = useSchemaContext();
  const [mode, setMode] = useState(origin === 'rescue' ? 'Pmax' : 'Ptmb');
  const [tableData, setTableData] = useState([]);
  const [ATData, setATData] = useState([]);
  const [canDisableTR, setCanDisableTR] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isSchemaReady, setIsSchemaReady] = useState(false);
  const [projection, setProjection] = useState(false);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (!switchAlreadyUsed) locationTransfo = {};
    if (results?.projetCalculs?.length) {
      setProjection(true);
    } else {
      setProjection(false);
    }
    if (results) {
      setTableData(results?.projetCalculs);
      setATData(results?.ATCalculations);
    }
  }, [results]);

  useEffect(() => {
    if (origin === 'rescue') {
      setCanDisableTR(true);
    }
  }, [origin]);

  useEffect(() => {
    if (schema.transformers.length && locationTransfo) {
      setLines(theseus(locationTransfo));
    }
  }, [schema.transformers.length]);

  const width =
    transformersElement?.length > 1
      ? `${Math.max(40, 80 / transformersElement.length)}em`
      : '40em';

  const update = async (column, demirame, type, callback = false) => {
    const newSchema = { ...schema };
    const base = newSchema.transformers[column].children[demirame];
    let change;
    switch (type) {
      case 'in':
        newSchema.transformers[column].children[demirame].electric_switch_in =
          !base.electric_switch_in;
        change = `Demie Rame '${
          newSchema.transformers[column].children[demirame].title
        }' interrupteur entrant => '${
          !base.electric_switch_in ? 'Ouvert' : 'Fermé'
        }'`;
        break;
      case 'out':
        newSchema.transformers[column].children[demirame].electric_switch_out =
          !base.electric_switch_out;
        change = `Demie Rame '${
          newSchema.transformers[column].children[demirame].title
        }' interrupteur sortant => '${
          !base.electric_switch_out ? 'Ouvert' : 'Fermé'
        }'`;
        break;
      case 'between':
        newSchema.transformers[column].children[
          demirame
        ].electric_switch_between = !base.electric_switch_between;
        change = `Demie Rame '${
          newSchema.transformers[column].children[demirame].title
        }' interrupteur intermédiaire => '${
          !base.electric_switch_between ? 'Ouvert' : 'Fermé'
        }'`;
        break;
      default:
        break;
    }

    if (!canSwitch(newSchema.transformers[column])) {
      notification.warn({
        message: 'Action impossible',
        key: 'switch_invalid_position'
      });
      update(column, demirame, type, true);
      // reverts the schema to its original state (not optimal but does the job and avoid inconsistent behavior)
      return;
    }

    await setSchema(newSchema);
    let typeOfPatch = 'TR';
    if (origin === 'projects') {
      typeOfPatch = 'PR';
    }
    if (origin === 'rescue') {
      typeOfPatch = 'RE';
    }

    await patchSchema(typeOfPatch);

    if (callback) return;
    await patchDemiRame({
      ...newSchema.transformers[column].children[demirame],
      change
    });
    if (newSchema.transformers[column].children.length > 1) {
      await patchDemiRame({
        ...newSchema.transformers[column].children[1],
        electric_switch_between:
          newSchema.transformers[column].children[demirame]
            .electric_switch_between
      });
    }

    getResource();
  };

  const checkSchemaReady = (el) => {
    if (!el || isSchemaReady) return;
    setOffset({
      x: el.getBoundingClientRect().x - 38,
      y: el.getBoundingClientRect().y
    });
    setIsSchemaReady(true);
  };

  return (
    !isSchemaLoading && (
      <div
        style={{ marginBottom: (schema?.transformers?.length || 6) * 20 }}
        ref={(el) => {
          checkSchemaReady(el);
        }}
      >
        {isSchemaReady &&
          !isSchemaLoading &&
          lines.map((line) => (
            <Lines
              key={line.x + Math.random()}
              x={line.x - offset.x}
              y={line.y - offset.y}
              height={line.height}
              width={line.width}
              color={line.color}
            />
          ))}
        <Row align="middle" justify="space-between" className="schema-top-menu">
          <Row>
            <SelectButton
              name="Ptmb"
              isSelected={mode === 'Ptmb'}
              onClick={() => setMode('Ptmb')}
            />
            <SelectButton
              name="P*max"
              isSelected={mode === 'Pmax'}
              onClick={() => setMode('Pmax')}
            />
          </Row>
          <Row style={{ flexDirection: 'row-reverse', gap: 8 }}>
            <Select
              style={{ width: 100 }}
              onSelect={(data) => {
                setSelectedYear(data);
              }}
              allowClear
              onClear={() => setSelectedYear()}
              value={selectedYear}
            >
              {(yearsProject || []).map((y) => (
                <Option key={y} value={y}>
                  {y}
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: 100 }}
              onSelect={(data) => {
                setRefYear(data);
              }}
              value={refYear}
            >
              {(yearsImport || []).map((y) => (
                <Option key={y} value={y}>
                  {y}
                </Option>
              ))}
            </Select>
          </Row>
        </Row>
        <div
          style={{
            display: 'inline-flex',
            minWidth: `${Math.max(
              100,
              parseInt(width.replace('em', ''), 10) * transformersElement &&
                transformersElement.length
            )}%`
          }}
        >
          <Row
            className="transformer-row"
            style={{ marginBottom: (schema?.transformers?.length || 6) * 12 }}
          >
            <TransformersMapping
              width={width}
              mode={mode}
              data={tableData}
              totals={results?.totalTransformers}
              canDisableTR={canDisableTR}
              projection={projection}
            />
          </Row>
        </div>
        <div
          style={{
            display: 'inline-flex',
            minWidth: `${Math.max(
              100,
              parseInt(width.replace('em', ''), 10) * transformersElement &&
                transformersElement.length
            )}%`
          }}
        >
          {isSchemaReady && (
            <ColumnMapping
              width={width}
              mode={mode}
              update={update}
              canEdit={canEdit}
              tableData={tableData}
              ATData={ATData}
              offset={offset}
              projection={projection}
            />
          )}
        </div>
      </div>
    )
  );
};

export default TransformersSchema;

SelectButton.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

TransformersSchema.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  results: PropTypes.shape({
    totalTransformers: PropTypes.arrayOf(PropTypes.shape({})),
    departsCalculsInitiaux: PropTypes.arrayOf(PropTypes.shape({})),
    departsCalculsContractuels: PropTypes.arrayOf(PropTypes.shape({})),
    producteursCalculsInitiaux: PropTypes.arrayOf(PropTypes.shape({})),
    projetCalculs: PropTypes.arrayOf(PropTypes.shape({})),
    ATCalculations: PropTypes.shape({})
  }).isRequired,
  getResource: PropTypes.func.isRequired,
  isSchemaLoading: PropTypes.bool.isRequired
};
