import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import useFields from './contractualReliefFields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateContractualRelief = ({ purpose }) => {
  const location = useLocation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const params = new URLSearchParams(location.search);
  const id = params.get('postesSources');
  const [departs, setDeparts] = useState([]);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        yearMES: data.yearMES && moment(data.yearMES)
      })
    }
  };

  const getResource = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/departs?sourcePost=${id}`
      });
      setDeparts(data);
    } catch (e) {
      message(e);
    }
  }, [purpose]);

  useEffect(() => {
    if (id) {
      (async () => {
        await getResource();
      })();
    }
  }, [getResource]);

  const { fields, isFieldsLoading } = useFields(id, departs);

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="contractualrelief"
      resource="contractualrelief"
      config={config}
    />
  );
};

CreateUpdateContractualRelief.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateContractualRelief;
